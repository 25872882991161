import Button from 'components/Button';
import ButtonAddBlog from 'widgets/NewReview';
import authSelectors from 'features/auth/services/selectors';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
import useGoNewReview from 'widgets/NewReview/useGoNewReview';

const WriteReviewSection = () => {
  const isAuth = authSelectors.isAuth();
  const navigate = useNavigate();

  const signUp = () => navigate('/sign-up');
  const login = () => navigate('/login');
  const goNewReview = useGoNewReview();

  const sm = useSMLayout();
  if (sm) return (
    <Styled>
      <div className="box-write-review">
        <div className="title-section">Write reviews</div>
        <div className="sub-title">Start by logging in or creating an account to be able to view and search more customer information in just a few seconds - for free.</div>
        <div className="actions">
          {isAuth ?
            <Button onClick={goNewReview} block type='primary' className="action-item">New Review</Button>
            : <>
              <Button onClick={signUp} block type='primary' className="action-item">Sign up for free</Button>
              <Button onClick={login} ghost block type='default' className="action-item">Log In</Button>
            </>}
        </div>
      </div>
    </Styled>
  );
  return (
    <Styled>
      <div className='ContainerWeb large'>
        <div className="box-write-review">
          <div className="title-section">Write reviews</div>
          <div className="sub-title">Start by logging in or creating an account to be able to view and search more customer information in just a few seconds - for free.</div>
          <div className="actions">
            {isAuth ?
              <ButtonAddBlog buttonRender={(onPress) => <Button onClick={onPress} block type='primary' className="action-item">New Review</Button>} />
              : <>
                <Button onClick={signUp} block type='primary' className="action-item">Sign up for free</Button>
                <Button onClick={login} ghost block type='default' className="action-item">Log In</Button>
              </>}
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default WriteReviewSection;
const Styled = styled.div`
  display: flex;
  padding: 32px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: #FFF;
  .box-write-review {
    border-radius: 32px;
    background: #0B3558;
    display: flex;
    padding: 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    .title-section {
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .sub-title {
      color: #E7EDF6;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 16px;
    }
    .actions {
      display: flex;
      align-items: center;
      gap: 16px;
      .action-item {
        flex: 1;
        display: flex;
        padding: 16px 22.19px 15.8px 23px;
        justify-content: center;
        align-items: center;
        height: 50px;
      }
    }
  }
`;
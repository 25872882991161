import { EditOutlined, SettingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Tabs, TabsProps } from 'antd';
import Button from 'components/Button';
import dayjs from 'dayjs';
import Header from 'features/home/components/Header';
import mineActions from 'features/mine/services/actions';
import mineSelectors from 'features/mine/services/selectors';
import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import EditProfileTab from './components/EditProfileTab';
import PasswordTab from './components/PasswordTab';
import ReviewTab from './components/ReviewTab';
import AvatarUser from './components/UserAvatar';

const items = [
  { label: '', key: 'reviews', children: <ReviewTab />, },
  { label: '', key: 'edit-profile', children: <EditProfileTab />, },
  { label: 'Password', key: 'password', children: <PasswordTab />, },
];

const RenderTabBar: TabsProps['renderTabBar'] = (event) => {
  const userInfo = mineSelectors.authInfo();
  return (
    <BoxUserInfo>
      <div className="box-container">
        <div className="top-box">
          <div className="account-info">
            <AvatarUser />
            <div className="account-name">{userInfo?.name || 'Unknown'}</div>
            <div className="account-email">{userInfo?.email || '--'}</div>
            <div className="account-phone">{userInfo?.phone ? formatPhone(userInfo?.phone) : '--'}</div>
          </div>
          <div className="business-box">
            <div className="business-name-row">
              <IconBusiness />
              <span>{userInfo?.businessName || '--'}</span>
            </div>
            <div className="business-address">
              {[userInfo?.address, userInfo?.city, userInfo?.state, userInfo?.zipCode].filter(o => !!o).join(', ')}
            </div>
          </div>
          <div className="actions">
            <Button
              onClick={(e) => event.onTabClick('reviews', e)}
              block size='large' success className='action-item'>
              <UnorderedListOutlined />
              Your Reviews</Button>
            <Button
              onClick={(e) => event.onTabClick('edit-profile', e)}
              block size='large' type='primary' className='action-item'>
              <EditOutlined />
              Edit Profile</Button>
            <Button
              onClick={(e) => event.onTabClick('password', e)}
              block size='large' type='default' className='action-item'>
              <SettingOutlined />
              Change Password</Button>
          </div>
        </div>
        <div className="total-reviews">
          <div className="total-review-item">
            <span>Your reviews</span>
            <span className="value">{userInfo?.totalReviews || 0}</span>
          </div>
          <div className="total-review-item">
            <span>Your draft reviews</span>
            <span className="value">{userInfo?.totalDraftReviews || 0}</span>
          </div>
        </div>
      </div>
      <div className="account-created-date">
        <span>MEMBER SINCE: {userInfo?.createdDate ? dayjs(userInfo?.createdDate).format('MM-DD-YYYY') : '--'}</span>
      </div>
    </BoxUserInfo>
  );
};

const UIWebAccountSettingPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(mineActions.initSetting());
  }, []);
  return (
    <PageStyled>
      <div className="header"><Header /></div>
      <div className="content-container">
        <TabContentStyled>
          <Tabs
            tabPosition={'left'}
            indicator={{ size: 0 }}
            defaultActiveKey='reviews'
            items={items}
            renderTabBar={RenderTabBar}
          />
        </TabContentStyled>
      </div>
    </PageStyled>
  );
};

export default UIWebAccountSettingPage;

const BoxUserInfo = styled.div`
    width: 20vw;
    min-width: 20vw;
    margin-right: 40px;
    .box-container {
      display: flex;
      padding-top: 12px;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      box-shadow: 0px 12px 24px -8px rgba(0, 107, 255, 0.10);
      background: #fff;
      padding: 12px 0;
      .top-box {
        padding: 0 24px;
        border-bottom: 1px solid #D6D6D6;
      }
    }
    .ant-avatar {
      border-radius: 100px;
      background: #dde6f4;
      .ant-avatar-string {
        transform: scale(1.5) translateY(3px) !important;
      }
    }
    .account-info {
      display: flex;
      flex-direction: column; 
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-bottom: 12px;
    }
    .account-name {
      color: #0B3558;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    .account-email, .account-phone {
      color: #476788;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .account-created-date {
      display: flex;
      height: 40px;
      padding: 0px 24px;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      background: #E7EDF6;
      span {
        color: #808080;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .business-box {
      display: flex;
      padding: 6px 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      border-radius: 5px;
      background: #E4E7FF;
      margin-bottom: 24px;
      .business-name-row {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        span {
          color: #476788;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .business-address {
        align-self: stretch;
        color: #476788;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      margin-bottom: 32px;

      .ant-btn.action-item {
        gap: 0;
      }
    }

    .total-reviews {
      display: flex;
      padding: 16px 24px 8px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      .total-review-item {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        span {
          flex: 1;
          color: #0B3558;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &.value {
            text-align: right;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
`;

const TabContentStyled = styled.div`
  flex: 1;
  display: flex;
  padding-bottom: 16px;
  width: 100%;
  .ant-tabs {
    flex: 1;
  }
  .ant-tabs-content-holder {
    border: none;
  }
  .ant-tabs-content.ant-tabs-content-left {
    height: 100%;
    .ant-tabs-tabpane {
      height: 100%;
    }
  }
  
`;

const PageStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    position: sticky;
    top: 0;
    z-index: 99;
  }
  background: #F2F3F8;
  .content-container {
    padding-top: 32px;
    width: 85%;
    margin: 0 auto;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;
const IconBusiness = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path d="M11.75 6.1875V0.5625H1.625V17.4375H6.125V14.0625H7.25V17.4375H17.375V6.1875H11.75ZM4.4375 15.1875H3.3125V14.0625H4.4375V15.1875ZM4.4375 12.375H3.3125V11.25H4.4375V12.375ZM4.4375 9.5625H3.3125V8.4375H4.4375V9.5625ZM4.4375 6.75H3.3125V5.625H4.4375V6.75ZM4.4375 3.9375H3.3125V2.8125H4.4375V3.9375ZM8.9375 2.8125H10.0625V3.9375H8.9375V2.8125ZM7.25 12.375H6.125V11.25H7.25V12.375ZM7.25 9.5625H6.125V8.4375H7.25V9.5625ZM7.25 6.75H6.125V5.625H7.25V6.75ZM7.25 3.9375H6.125V2.8125H7.25V3.9375ZM10.0625 15.1875H8.9375V14.0625H10.0625V15.1875ZM10.0625 12.375H8.9375V11.25H10.0625V12.375ZM10.0625 9.5625H8.9375V8.4375H10.0625V9.5625ZM10.0625 6.75H8.9375V5.625H10.0625V6.75ZM16.25 16.3125H11.75V15.1875H12.875V14.0625H11.75V12.375H12.875V11.25H11.75V9.5625H12.875V8.4375H11.75V7.3125H16.25V16.3125Z" fill="#476788" />
    <path d="M14 14.0625H15.125V15.1875H14V14.0625ZM14 11.25H15.125V12.375H14V11.25ZM14 8.4375H15.125V9.5625H14V8.4375Z" fill="black" />
  </svg>
);

import { Skeleton } from 'antd';
import { IAuthUserInfo } from 'features/auth/services/types/auth';
import { IBlogItem } from 'features/home/services/types/blog';
import { IUserHistoryActionItem } from 'features/mine/services/types/history';
import styled from 'styled-components';
import Actions, { ActionsSkeleton } from './Actions';
import Avatar, { AvatarSkeleton } from './Avatar';
import CustomerInfo, { CustomerInfoSkeleton } from './CustomerInfo';
import { ImageSkeleton } from './Images';
import InfoUser, { InfoUserSkeleton } from './InfoUser';
import SMImages from './SMImages';
type Props = {
  data?: IBlogItem;
  historyActions?: IUserHistoryActionItem[];
  authInfo?: IAuthUserInfo | null;
  isAuth?: boolean;
  mine?: boolean;
  onDelete?: () => void;
  draft?: boolean;
};
const BlogSM = ({ data, historyActions = [], authInfo, isAuth, mine, onDelete, draft }: Props) => {
  const { avatar, userName, address, city, state, zipCode, businessName, createdDate } = data || {};

  const _address = [address, city, state, zipCode].filter(o => !!o).join(', ');

  const activeLike = !!historyActions.find(o => (o.blogId === data?.id) && o.action === 'LIKE');
  const activeDisLike = !!historyActions.find(o => (o.blogId === data?.id) && o.action === 'DISLIKE');
  return (
    <Styled>
      <div className="group-avatar-box">
        {!mine ? <>
          <Avatar avatar={avatar} userName={userName} />
          <div style={{ marginBottom: '8px' }} />
        </> : null}
        <InfoUser
          draft={draft}
          mine={mine}
          address={_address} businessName={businessName} createdDate={createdDate}
          onDelete={onDelete}
          small
        />
      </div>
      <div style={{ marginBottom: '12px' }} />
      <ReviewContent style={{ width: '100%' }}>
        <div>
          <CustomerInfo name={data?.customerName} phone={data?.customerPhone} sm />
          <div style={{ marginBottom: '12px' }} />
          <div className='review-note'>{data?.note || ''}</div>
          <div style={{ marginBottom: '16px' }} />
        </div>
        <SMImages images={data?.images} />
        <div style={{ marginBottom: '12px' }} />
        <Actions
          data={data}
          sm blogId={data?.id}
          activeLike={activeLike}
          activeDislike={activeDisLike}
          authInfo={authInfo}
          isAuth={isAuth}
        />
      </ReviewContent>
    </Styled>
  );
};

export default BlogSM;
export const BlogSMSkeleton = () => {
  return (
    <Styled>
      <AvatarSkeleton />
      <div style={{ marginBottom: '8px' }} />
      <InfoUserSkeleton />
      <div style={{ marginBottom: '24px' }} />
      <ReviewContent style={{ width: '100%' }}>
        <div>
          <CustomerInfoSkeleton sm />
          <div style={{ marginBottom: '12px' }} />
          <Skeleton.Input block active style={{ height: 18 }} />
          <div style={{ marginBottom: '16px' }} />
        </div>
        <ImageSkeleton />
        <div style={{ marginBottom: '12px' }} />
        <ActionsSkeleton sm />
      </ReviewContent>
    </Styled>
  );
};


const ReviewContent = styled.div`
  .review-note {
    color: #0B3558;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const Styled = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 5px;
  border-bottom: 1px solid #E7EDF6;
  background: #FFF;
  .group-avatar-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
import { BlogLGSkeleton } from 'components/Blog/BlogLG';
import EmptyReview from 'components/EmptyReview';
import { IApiGetBlogParams } from 'features/home/services/types/api';
import { IBlogItem } from 'features/home/services/types/blog';
import styled from 'styled-components';
import ReviewPagination from './Pagination';
type Props = {
  loading: boolean;
  data: IBlogItem[];
  renderItem: (o: IBlogItem, index: number) => any;
  totalElements: number;
  params: IApiGetBlogParams;
  onChangePage: (page: number) => void;
};
const ReviewListWebUI = ({ loading, data = [], renderItem, totalElements, params, onChangePage }: Props) => {
  if (loading) return <LoadingUI />;
  if (!data?.length) return <EmptyUI />;
  return (
    <Styled>
      <div className="data-box">
        <div className="scroll-box-container">
          <div className="scroll-box">
            {data.map(renderItem)}
          </div>
        </div>
        <ReviewPagination
          totalElements={totalElements}
          params={params}
          onChangePage={onChangePage}
        />
      </div>
    </Styled>
  );
};

export default ReviewListWebUI;

const LoadingUI = () => (
  <Styled style={{ gap: 16 }}>
    <BlogLGSkeleton />
    <BlogLGSkeleton />
  </Styled>
);

const EmptyUI = () => (
  <Styled>
    <div className="empty-box">
      <EmptyReview />
    </div>
  </Styled>
);


const Styled = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .empty-box {
    border-radius: 5px;
    width: 100%;
    background: #fff;
    height: 268px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .data-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
    .scroll-box-container {
      position: relative;
      flex: 1;
      
    }
    .scroll-box {
      position: absolute;
      inset: 0;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
`;

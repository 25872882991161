import { Form, Input, notification } from 'antd';
import AddressPrefix from 'assets/Icons/SignUp/AddressPrefix';
import BusinessPrefix from 'assets/Icons/SignUp/BusinessPrefix';
import EmailPrefix from 'assets/Icons/SignUp/EmailPrefix';
import PhonePrefix from 'assets/Icons/SignUp/PhonePrefix';
import UserNamePrefix from 'assets/Icons/SignUp/UserNamePrefix';
import Button from 'components/Button';
import InputPhone from 'components/InputPhone';
import { IAuthUserInfo } from 'features/auth/services/types/auth';
import { IBodyApiUpdateInfo } from 'features/home/services/types/api';
import mineActions from 'features/mine/services/actions';
import mineApis from 'features/mine/services/apis';
import mineSelectors from 'features/mine/services/selectors';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import { useSMLayout } from 'utils/withLayout';

const EditProfileTab = () => {
  const sm = useSMLayout();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const userInfo = mineSelectors.authInfo();
  const setLoading = useSetLoadingPage();

  useEffect(() => {
    form.setFieldsValue({
      address: userInfo?.address || '',
      businessName: userInfo?.businessName || '',
      city: userInfo?.city || '',
      email: userInfo?.email || '',
      name: userInfo?.name || '',
      phone: formatPhone(userInfo?.phone || ''),
      state: userInfo?.state || '',
      zipCode: userInfo?.zipCode || '',
    });
  }, [userInfo]);
  const handleFinish = async (values: any) => {
    setLoading(true);
    const body: IBodyApiUpdateInfo = {
      address: values.address,
      businessName: values.businessName,
      city: values.city,
      email: values.email,
      name: values.name,
      phone: values.phone?.replace(/[^0-9]/g, ''),
      state: values.state,
      zipCode: values.zipCode,
    };
    try {
      const res: IResponseDataBody<boolean> = await mineApis.updateInfo(body);
      if (res.data.data) {
        notification.success({
          message: 'Updated Successfully',
          duration: 3,
        });
        dispatch(mineActions.getDetailInfo.fetch());
      }
    } catch (error) {
      const msg = get(error, 'response.data.message', '');
      notification.error({
        message: msg || 'fail!',
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Styled className={sm ? 'small' : ''}>
      <Form
        className='form-info' size='large' layout='vertical' form={form} onFinish={handleFinish}
        validateTrigger={['onChange']}
      >
        <div className="form-container">
          <div className="form-container-item form-container-left">
            <Form.Item label="Name" name={'name'}>
              <Input prefix={<UserNamePrefix />} placeholder={'Name'} allowClear />
            </Form.Item>
            <Form.Item label="Email" name={'email'}>
              <Input disabled prefix={<EmailPrefix />} placeholder={'Email'} allowClear />
            </Form.Item>
            <Form.Item label="Business Name" name={'businessName'}>
              <Input prefix={<BusinessPrefix />} placeholder={'Business Name'} allowClear />
            </Form.Item>
            <Form.Item label="Phone number" name={'phone'}>
              <InputPhone prefix={<PhonePrefix />} placeholder={'Phone number'} allowClear />
            </Form.Item>
          </div>
          <div className="form-container-item form-container-right">
            <Form.Item label="Address" name={'address'}>
              <Input prefix={<AddressPrefix />} placeholder={'Address'} allowClear />
            </Form.Item>
            <Form.Item label="City" name={'city'}>
              <Input prefix={<AddressPrefix />} placeholder={'City'} allowClear />
            </Form.Item>
            <Form.Item label="State" name={'state'}>
              <Input prefix={<AddressPrefix />} placeholder={'State'} allowClear />
            </Form.Item>
            <Form.Item label="Zip Code" name={'zipCode'}>
              <Input prefix={<AddressPrefix />} placeholder={'Zip Code'} allowClear />
            </Form.Item>
          </div>
        </div>
        <Form.Item shouldUpdate className="submit-form-item">
          {({ getFieldsValue, }) => {
            const values: IAuthUserInfo = getFieldsValue();

            const valueObj: Record<string, boolean> = {};
            Object.entries(values).map(([_key, value]) => {
              const key = _key as keyof IAuthUserInfo;
              let newValue: string = value || '';
              let oldValue: string = userInfo?.[key] || '';
              if (key === 'phone') {
                newValue = newValue.replace(/[^0-9]/g, '');
                oldValue = oldValue.replace(/[^0-9]/g, '');
              }
              const isValid = !!newValue && (newValue?.trim() !== oldValue);
              valueObj[key] = isValid;
            });

            const isValid = Object.values(valueObj).some(o => o);
            return (
              <div className='btn-submit-wrap'>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid}
                  block={sm}
                >
                  Save Changes
                </Button>
              </div>
            );
          }}
        </Form.Item>
      </Form>
    </Styled>
  );
};

export default EditProfileTab;
const Styled = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
  background: #FFF;
  border-radius: 5px;
  .ant-form-item.submit-form-item {
    margin-bottom: 0;
  }
  .form-info {
    width: 100%;
  }
  .form-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    .ant-form-item {
      margin-bottom: 12px;
      .ant-form-item-label {
        padding: 0;
        label {
          height: unset;
        }
      }
    }
    .ant-form-item.submit {
      margin-bottom: 0;
    }
    .form-container-item {
      display: flex;
      flex-direction: column;
    }
  }
  .btn-submit-wrap {
    display: flex;
    width: 100%;
    justify-content: end;
  }

  &.small {
    padding: 0;
    .form-info {
      padding-bottom: 16px;
    }
    .form-container {
      width: unset;
      display: flex;
      flex-direction: column;
      padding: 16px;
      padding-bottom: 50px;
      gap: 0;
      .ant-form-item {
        margin-bottom: 12px;
        .ant-form-item-label {
          padding: 0;
          label {
            height: unset;
          }
        }
      }
    }
    .ant-form-item.submit-form-item {
      padding: 16px 16px;
      position: fixed;
      background: #fff;
      bottom: 0;
      z-index: 99;
      left: 0;
      right: 0;
      border-top: 1px solid #E5E5E5;
    }
  }
`;
import { PlusOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
import ButtonAddBlog from 'widgets/NewReview';
import useGoNewReview from 'widgets/NewReview/useGoNewReview';

const AddNewReview = () => {
  const sm = useSMLayout();
  const goNewReview = useGoNewReview();

  if (sm) return (
    <AppStyled>
      <div className="content-container">
        <div className="title-section">Latest REVIEWS</div>
        <Button onClick={goNewReview} size='large' type='primary' success ><PlusOutlined /> New Review</Button>
      </div>
    </AppStyled>
  );

  return (
    <WebStyled>
      <div className='ContainerWeb'>
        <div className="content-container">
          <div className="title-section">Latest REVIEWS</div>
          <ButtonAddBlog buttonRender={(onPress) =>
            <Button onClick={onPress} size='large' type='primary' success ><PlusOutlined /> New Review</Button>}
          />
        </div>
      </div>
    </WebStyled>
  );
};

export default AddNewReview;
const Styled = styled.div`
  display: flex;
  align-items: center;
  .ContainerWeb {
    display: flex;
    align-items: center;
  }
  .content-container {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #808080;
  }
  .title-section {
    flex: 1;
    color: #476788;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.8px; /* 160% */
    text-transform: uppercase;
  }
`;
const AppStyled = styled(Styled)`
  .content-container {
    padding: 16px 0;
    margin-right: 16px;
    margin-left: 16px;
  }
`;
const WebStyled = styled(Styled)``;

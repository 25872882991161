import { debounce } from 'lodash';
import { useMemo, useState } from 'react';

type InputProps = {
  value?: string;
  timeDebounce?: number;
  onDebounce?: (text: string) => void;
};
const useDebounce = ({
  onDebounce = () => undefined,
  timeDebounce = 200,
  value = '',
}: InputProps) => {
  const [text, setText] = useState<string>(value);
  const onChangeText = useMemo(() => {
    return debounce(val => onDebounce(val), timeDebounce);
  }, [onDebounce]);

  const handleSetText = (_val: string) => {
    setText(_val);
    onChangeText(_val);
  };
  return { text, setText: handleSetText };
};

export default useDebounce;

import React from 'react';

const PasswordPrefix = () => {
  return (
    <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_4009_23285" fill="white">
        <path d="M0 0H38V26H0V0Z" />
      </mask>
      <path d="M37 0V26H39V0H37Z" fill="#E7EDF6" mask="url(#path-1-inside-1_4009_23285)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.0003 7.75016C18.3815 7.75016 17.788 7.996 17.3504 8.43358C16.9128 8.87117 16.667 9.46466 16.667 10.0835V11.8335H21.3337V10.0835C21.3337 9.46466 21.0878 8.87117 20.6502 8.43358C20.2127 7.996 19.6192 7.75016 19.0003 7.75016ZM15.5003 10.0835V11.8335H14.917C13.9505 11.8335 13.167 12.617 13.167 13.5835V17.6668C13.167 18.6333 13.9505 19.4168 14.917 19.4168H23.0837C24.0502 19.4168 24.8337 18.6333 24.8337 17.6668V13.5835C24.8337 12.617 24.0502 11.8335 23.0837 11.8335H22.5003V10.0835C22.5003 9.15524 22.1316 8.265 21.4752 7.60862C20.8188 6.95224 19.9286 6.5835 19.0003 6.5835C18.0721 6.5835 17.1818 6.95224 16.5255 7.60862C15.8691 8.265 15.5003 9.15524 15.5003 10.0835Z" fill="#808080" />
    </svg>
  );
};

export default PasswordPrefix;

import dayjs from 'dayjs';

const getCreatedDateLabel = (data?: string | null | undefined) => {
  if (!data) return '--';
  const now = dayjs();
  const date = dayjs(data, 'MM-DD-YYYY HH:mm:ss');
  if (!date.isValid()) return '--';

  if (date.isSame(now, 'date')) {
    const second = now.diff(date, 'second');
    if (second < 60) {
      if (second === 1) return '1 second before';
      return `${second || 1} seconds before`;
    }
    const minute = now.diff(date, 'minute');
    if (minute < 60) {
      if (minute === 1) return '1 minute before';
      return `${minute || 1} minutes before`;
    }
    const hour = now.diff(date, 'hour');
    if (hour === 1) return '1 hour before';
    return `${hour} hours before`;
  }
  const yesterday = now.clone().subtract(1, 'day');
  if (date.isSame(yesterday, 'date')) {
    return `Yesterday ${date.format('hh:mm A')}`;
  }

  return date.format('MM-DD-YYYY hh:mm A');
};

export default getCreatedDateLabel;

import BlogLG, { BlogLGSkeleton } from 'components/Blog/BlogLG';
import EmptyReview from 'components/EmptyReview';
import homeSelectors from 'features/home/services/selectors';
import styled from 'styled-components';
import ReviewPagination from './Pagination';
import mineSelectors from 'features/mine/services/selectors';
import authSelectors from 'features/auth/services/selectors';

const WebReviewList = () => {
  const loading = homeSelectors.getBlogLoading();
  const data = homeSelectors.getBlogs();
  const historyActions = mineSelectors.historyActions();
  const authInfo = mineSelectors.authInfo();
  const isAuth = authSelectors.isAuth();


  if (loading) return <LoadingUI />;
  if (!data?.length) return <EmptyUI />;

  return (
    <Styled>
      <div className="data-box">
        {data.map(o => (
          <BlogLG
            key={o.id}
            data={o}
            historyActions={historyActions}
            authInfo={authInfo}
            isAuth={isAuth}
          />
        ))}
      </div>
      <ReviewPagination />
    </Styled>
  );
};

export default WebReviewList;

const LoadingUI = () => (
  <Styled style={{ gap: 16 }}>
    <BlogLGSkeleton />
    <BlogLGSkeleton />
  </Styled>
);

const EmptyUI = () => (
  <Styled>
    <div className="empty-box">
      <EmptyReview />
    </div>
  </Styled>
);

const Styled = styled.div`
  .empty-box {
    border-radius: 5px;
    width: 100%;
    background: #fff;
    height: 268px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .data-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: #fff;
    margin-bottom: 16px;
  }
`;

import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiGetBlogParams } from './types/api';
import { IBlogItem } from './types/blog';

const init = createAction(PREFIX_ACTIONS + 'initData');

const setParams = createAction<IApiGetBlogParams>(PREFIX_ACTIONS + 'setParams');
const getBlogs = createAsyncAction<IApiGetBlogParams>(PREFIX_ACTIONS + 'getBlogs');
const getBlogsMostInteraction = createAsyncAction(PREFIX_ACTIONS + 'getBlogsMostInteraction');
const enableSearch = createAction<boolean>(PREFIX_ACTIONS + 'enableSearch');
const getBlogsSearch = createAsyncAction<IApiGetBlogParams>(PREFIX_ACTIONS + 'getBlogsSearch');
const setBlogsSearchParams = createAction<IApiGetBlogParams>(PREFIX_ACTIONS + 'setBlogsSearchParams');

const afterAddNew = createAction(PREFIX_ACTIONS + 'afterAddNew');
const updateBlogItem = createAction<IBlogItem>(PREFIX_ACTIONS + 'updateBlogItem');


export const blogActions = {
  like: createAsyncAction<string>(PREFIX_ACTIONS + '_BLOG_ACTION_' + 'doLike'),
  dislike: createAsyncAction<string>(PREFIX_ACTIONS + '_BLOG_ACTION_' + 'doDislike'),
  doComment: createAsyncAction<{ blogId: string, comment: string }>(PREFIX_ACTIONS + '_BLOG_ACTION_' + 'doComment'),
};

const setDraftDataReport = createAction<IBlogItem | null>(PREFIX_ACTIONS + 'setDraftDataReport');

const homeActions = {
  init,
  getBlogs,
  setParams,
  getBlogsSearch,
  setBlogsSearchParams,
  getBlogsMostInteraction,
  enableSearch,
  blogActions,
  afterAddNew,
  updateBlogItem,
  setDraftDataReport,
};

export default homeActions;


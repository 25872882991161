import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import mineActions from 'features/mine/services/actions';

const initialState: IState = {
  dataDemo: [],
  isAuth: false,
  enableGuardLogin: false,
  userSignUpInfo: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.setUserSignUpInfo, (state, { payload }) => {
        state.userSignUpInfo = payload;
      })
      .addCase(actions.logout, (state) => {
        state.isAuth = false;
      })
      .addCase(mineActions.setAuthUserInfo, (state, { payload }) => {
        if (payload) state.isAuth = true;
        else state.isAuth = false;
      })
      .addCase(actions.enableGuardLogin, (state, { payload }) => {
        state.enableGuardLogin = payload;
      })
      .addCase(actions.getDemoData.success, (state, { payload }) => {
        state.dataDemo = payload;
      })
      ;
  },
});

/** TODO:
 * please replace auth => your feature name
 * ex:
 *      my feature is booking 
 *  name sagas like
 *      authServiceReducer => bookingServiceReducers
 * 
 * // TODO:
 * Move to rootReducer (src/store/root/reducers.ts) and import your feature reducer
 */

const authServiceReducer = Slice.reducer;
export default authServiceReducer;

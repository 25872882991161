import { PlusOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import Button from 'components/Button';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
import ButtonAddBlog from 'widgets/NewReview';
import DraftReviews from './DraftReviews';
import Reviews from './Reviews';


const items: TabsProps['items'] = [
  {
    key: 'review',
    label: 'Your Reviews',
    children: <Reviews />,
  },
  {
    key: 'draft-review',
    label: 'Your Draft Reviews',
    children: <DraftReviews />,
  },
];

const ReviewTab = () => {
  const sm = useSMLayout();
  return (
    <Styled className={sm ? 'small' : ''}>
      {!sm ? <ButtonAddBlog mine buttonRender={(onPress) =>
        <Button className='btn-add-new' onClick={onPress} size='large' type='primary' success ><PlusOutlined /> New Review</Button>}
      /> : null}
      <Tabs
        className='review-tab-content'
        indicator={{ size: 0 }}
        defaultActiveKey="1" items={items} />
    </Styled>
  );
};

export default ReviewTab;
const Styled = styled.div`
  height: 100%;
  .btn-add-new {
    position: absolute;
    right: 0;
    z-index: 9;
  }
  .review-tab-content.ant-tabs {
    height: 100%;
    .ant-tabs-content.ant-tabs-content-top {
      height: 100%;
    }
    .ant-tabs-nav-list {
      gap: 8px;
      .ant-tabs-tab {
        display: flex;
        height: 44px;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin: 0;
        .ant-tabs-tab-btn {
          color: #476788;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        &.ant-tabs-tab-active {
          border-radius: 100px;
          background: #0B3558;
          .ant-tabs-tab-btn {
            color: #FFF;
          }
        }
      }
    }
  }

  &.small {

    .review-tab-content.ant-tabs {
      height: 100%;
      .ant-tabs-content.ant-tabs-content-top {
        height: 100%;
      }
      .ant-tabs-nav-list {
        flex: 1;
        padding: 0 16px;
        .ant-tabs-tab {
          flex: 1;
          .ant-tabs-tab-btn {
           
          }
          &.ant-tabs-tab-active {
           
            .ant-tabs-tab-btn {
            }
          }
        }
      }
    }
  }
`;
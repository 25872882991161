import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
import leftImage from './left-image.png';
import leftSMImage from './left-sm-image.png';
import rightImage from './right-image.png';
import rightSMImage from './right-sm-image.png';
import useDebounce from 'hooks/useDebounce';
import homeSelectors from 'features/home/services/selectors';
import { useAppDispatch } from 'store/hooks';
import homeActions from 'features/home/services/actions';
import dayjs from 'dayjs';

const Banner = () => {
  const sm = useSMLayout();
  if (sm) return (
    <Styled>
      <ContainerApp className='ContainerApp'>
        <div className="header">
          <img src={leftSMImage} alt="" className="left-image" />
          <div className="title-section">BanList</div>
          <img src={rightSMImage} alt="" className="right-image" />
        </div>
        <div className="sub-title">
          BanList is a platform that shows salon reviews to customers who use services at salons. Log in to interact and post your review
        </div>
        <InputSearch />
      </ContainerApp>
    </Styled>
  );
  return (
    <Styled>
      <ContainWeb className='ContainerWeb large'>
        <div className="content-container">
          <img src={leftImage} alt="" className="left-image" />
          <div className="center-content">
            <div className="title-section">BanList</div>
            <div className="sub-title">
              BanList is a platform that shows salon reviews to customers who use services at salons.<br />Log in to interact and post your review
            </div>
            <InputSearch />
          </div>
          <img src={rightImage} alt="" className="right-image" />
        </div>
      </ContainWeb>
    </Styled>
  );
};

export default Banner;

const InputSearch = () => {
  const params = homeSelectors.getParams();
  const dispatch = useAppDispatch();
  const { text, setText } = useDebounce({
    value: params.keySearch, onDebounce(text) {
      if (!text) dispatch(homeActions.enableSearch(false));
    },
  });

  const handleSearch = () => {
    const startDate = dayjs().subtract(1, 'day').format('MM-DD-YYYY');
    const endDate = dayjs().format('MM-DD-YYYY');
    dispatch(homeActions.setBlogsSearchParams({
      keySearch: text, page: 1,
      startDate, endDate, sortBy: undefined,
    }));
  };

  return (
    <Input
      value={text}
      onChange={e => setText(e.target.value)}
      className='input-search'
      placeholder='Search name/ phone number'
      suffix={<Button size='large' type='primary' onClick={e => [e.stopPropagation(), e.preventDefault(), handleSearch()]}><SearchOutlined /> Search</Button>}
    />
  );
};


const ContainerApp = styled.div`
&.ContainerApp {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  .header {
    display: flex;
    align-items: center;
    margin: 0px -16px;
    .title-section {
      color: #0B3558;
      text-align: center;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      flex: 1;
      align-self: end;
      padding-bottom: 4px;
    }
    .left-image, .right-image {
      height: 85px; width: 85px;
    }
  }
  .sub-title {
    color: #476788;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin-bottom: 24px;
  }
}
`;

const ContainWeb = styled.div`
  position:relative; 
  z-index: 1;
  height: 308px;
  display: flex;
  align-items: center;
  .ant-input {
    font-size: 18px;
  }
  .content-container {
    flex: 1;
    display: flex;
    // margin: 0 -20px;
    .left-image {
      width: 112px; height: 100px;
      align-self: start;
    }
    .right-image {
      width: 101px; height: 101px;
      align-self: end;
      // transform: translateY(18px);
    }
    .center-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 24px;
    }
  }
`;

const Styled = styled.div`
  position:relative; 
  z-index: 1;
  background: linear-gradient(90deg, #E1E4FF 0%, #EFF1FF 100%);
  .ContainerWeb {
    height: 308px;
    display: flex;
    align-items: center;
  }
  .title-section {
    color: #0B3558;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
  }
  .sub-title {
    color: #476788;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    margin-bottom: 32px;
  }
  .ant-input-affix-wrapper >input.ant-input {
    color: #0B3558;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
  }
`;
import { Skeleton } from 'antd';
import { IAuthUserInfo } from 'features/auth/services/types/auth';
import { IBlogItem } from 'features/home/services/types/blog';
import { IUserHistoryActionItem } from 'features/mine/services/types/history';
import styled from 'styled-components';
import Actions, { ActionsSkeleton } from './Actions';
import Avatar, { AvatarSkeleton } from './Avatar';
import CustomerInfo, { CustomerInfoSkeleton } from './CustomerInfo';
import Images, { ImageSkeleton } from './Images';
import InfoUser, { InfoUserSkeleton } from './InfoUser';
type Props = {
  data?: IBlogItem;
  historyActions?: IUserHistoryActionItem[];
  authInfo?: IAuthUserInfo | null;
  isAuth?: boolean;
  mine?: boolean;
  onDelete?: () => void;
  draft?: boolean;
};
const BlogLG = ({ data, historyActions = [], authInfo, isAuth, mine, onDelete, draft }: Props) => {
  const { avatar, userName, address, city, state, zipCode, businessName, createdDate } = data || {};

  const _address = [address, city, state, zipCode].filter(o => !!o).join(', ');

  const activeLike = !!historyActions.find(o => (o.blogId === data?.id) && o.action === 'LIKE');
  const activeDisLike = !!historyActions.find(o => (o.blogId === data?.id) && o.action === 'DISLIKE');

  return (
    <Styled>
      <div className="user-info-group" style={mine ? { width: '100%' } : {}}>
        {!mine ? <Avatar avatar={avatar} userName={userName} /> : null}
        <InfoUser
          draft={draft}
          mine={mine}
          address={_address}
          businessName={businessName}
          createdDate={createdDate}
          onDelete={onDelete}
        />
      </div>
      <ReviewContent>
        <div className='customer-ban'>
          <CustomerInfo name={data?.customerName} phone={data?.customerPhone} />
          <div style={{ marginBottom: '12px' }} />
          <div className='review-note'>{data?.note}</div>
        </div>
        <Images images={data?.images} />
      </ReviewContent>
      <Actions
        blogId={data?.id}
        data={data}
        activeLike={activeLike}
        activeDislike={activeDisLike}
        authInfo={authInfo}
        isAuth={isAuth}
        mine={mine}
      />
    </Styled>
  );
};

export default BlogLG;

export const BlogLGSkeleton = () => {
  return (
    <Styled>
      <div className="user-info-group">
        <AvatarSkeleton />
        <InfoUserSkeleton />
      </div>
      <ReviewContent>
        <div className='customer-ban'>
          <CustomerInfoSkeleton />
          <div style={{ marginBottom: '12px' }} />
          <div style={{ marginRight: 16 }}>
            <Skeleton.Input block active style={{ height: 18 }} />
          </div>
          <div style={{ marginBottom: '16px', flex: 1 }} />
          <ActionsSkeleton />
        </div>
        <ImageSkeleton />
      </ReviewContent>
    </Styled>
  );
};

const ReviewContent = styled.div`
  display: flex;
  width: 100%;
  .customer-ban {
    flex: 1;
    display: flex; 
    flex-direction: column;
  }
  .review-note {
    color: #0B3558;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const Styled = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
border-radius: 5px;
border-bottom: 1px solid #E7EDF6;
background: #fff;

  .user-info-group {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

import React from 'react';
import styled from 'styled-components';
import FormVerify from './components/FormVerify';
import Header from 'features/auth/components/Header';

const UIMobileVerifyPage = () => {
  return (
    <Styled>
      <Header />
      <div style={{ marginBottom: '32px' }} />
      <FormVerify />
      <div style={{ marginBottom: '16px' }} />
    </Styled>
  );
};

export default UIMobileVerifyPage;
const Styled = styled.div`
  background: #F2F3F8;
  padding-right: 16px;
  padding-left: 16px;
`;
import { Form, notification } from 'antd';
import { useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { MessageErrorSizeImageRef } from './MessageErrorSizeImage';
import authSelectors from 'features/auth/services/selectors';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import authActions from 'features/auth/services/actions';
import { IFormInfoValues } from './FormInfo';
import homeApis from 'features/home/services/apis';
import homeActions from 'features/home/services/actions';
import { useSMLayout } from 'utils/withLayout';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import mineActions from 'features/mine/services/actions';

const useNewReviewHook = (mine?: boolean) => {
  const sm = useSMLayout();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const msgErrorImageRef = useRef<MessageErrorSizeImageRef>(null);
  const isAuth = authSelectors.isAuth();
  const isDraft = useRef(false);
  const setLoading = useSetLoadingPage();
  const navigate = useNavigate();

  const onClose = () => {
    setVisible(false);
    if (sm) {
      navigate('/');
    }
  };
  const onOpen = () => {
    if (!isAuth) {
      dispatch(authActions.enableGuardLogin(true));
      return;
    }
    setVisible(true);
    form.resetFields();
  };

  const handleFinish = async (values: IFormInfoValues) => {
    const formData = new FormData();
    formData.append('customerName', values.customerName);
    formData.append('customerPhone', values.customerPhone);
    formData.append('content', values.content);
    formData.append('address', values.customerAddress);
    formData.append('city', values.city);
    formData.append('state', values.state);
    formData.append('status', isDraft.current ? 'DRAFT' : 'PUBLISH');
    formData.append('customerId', values.customerData?.id || '');
    values.images?.map(o => {
      formData.append('file', o.originFileObj);
    });
    setVisible(false);
    setLoading(true);
    try {
      const res = await homeApis.addBlog(formData);
      if (res.data.data) {
        if (mine) {
          if (isDraft.current) {
            dispatch(mineActions.setDraftParams({ page: 1 }));
          } else {
            dispatch(mineActions.setParams({ page: 1 }));
          }
        } else {
          dispatch(homeActions.afterAddNew());
        }
        notification.success({
          message: 'Created Successfully',
          duration: 3,
        });
        if (sm) {
          navigate('/');
        }
      }
    } catch (error) {
      const msg = get(error, 'response.data.message', '');
      notification.error({
        message: msg || 'fail!',
        duration: 3,
      });
    }
    finally {
      setLoading(false);
    }
  };

  const onSave = () => {
    isDraft.current = false;
    form.submit();
  };
  const onSaveDraft = () => {
    isDraft.current = true;
    form.submit();
  };

  return ({
    onOpen, onClose, visible,
    form, handleFinish, msgErrorImageRef,
    onSaveDraft, onSave,
  });
};

export default useNewReviewHook;

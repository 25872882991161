import logoIcon from 'assets/logo.png';
import Button from 'components/Button';
import authSelectors from 'features/auth/services/selectors';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
import HeaderFullAccess from './HeaderFullAccess';

const HeaderNonLogin = () => {
  const navigate = useNavigate();
  const backToHome = () => navigate('/');
  const sm = useSMLayout();
  if (sm) return (
    <Styled small>
      <img onClick={backToHome} src={logoIcon} className='logo-app' />
      <div style={{ flex: 1 }} />
      <Button onClick={() => navigate('/login')} size='large' ghost style={{ border: 'none', color: '#0B3558' }} type='text'>Log in</Button>
      <Button onClick={() => navigate('/sign-up')} size='large' type='primary'>Sign Up</Button>
    </Styled>
  );
  return (
    <Styled>
      <div className='ContainerWeb large'>
        <img onClick={backToHome} src={logoIcon} className='logo-app' />
        <div style={{ flex: 1 }} />
        <Button onClick={() => navigate('/login')} size='large' ghost style={{ border: 'none', color: '#0B3558' }} type='text'>Log in</Button>
        <Button onClick={() => navigate('/sign-up')} size='large' type='primary'>Sign Up</Button>
      </div>
    </Styled>
  );
};

const Header = () => {
  const isAuth = authSelectors.isAuth();

  if (isAuth) return <HeaderFullAccess />;
  return <HeaderNonLogin />;
};
export default Header;



const Styled = styled.div<{ small?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background: #FFF;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.10);
  padding: 10px 16px;

  .ContainerWeb {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .logo-app {
    height: 36px;
  }
  .user-info-actions {
    display: flex;
    align-items: center;
  }
  .avatar-user-group {
    display: flex;
    align-items: center;
    gap: 8px;
    .ant-avatar {
      border-radius: 100px;
      background: #dde6f4;
    }
    span {
      font-size: 14px;
    }
  }

  ${({ small }) => small ? `
  .logo-app {
    height: 28px;
  } 

  .user-info-actions {
    gap: 8px;
    .avatar-user-group {
      gap: 4px;
      span {
        color: #0B3558;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  `: ''}
`;
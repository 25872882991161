import { SendOutlined } from '@ant-design/icons';
import { Avatar, Collapse, Form, Input, InputRef, Skeleton, Spin } from 'antd';
import DefaultAvatar from 'assets/Icons/DefaultAvatar';
import { CommentIcon, DislikeIcon, LikeIcon, ReportIcon } from 'assets/Icons/SocialIcons';
import Button from 'components/Button';
import dayjs from 'dayjs';
import authActions from 'features/auth/services/actions';
import { IAuthUserInfo } from 'features/auth/services/types/auth';
import homeActions, { blogActions } from 'features/home/services/actions';
import homeApis from 'features/home/services/apis';
import { BlogAction } from 'features/home/services/constants';
import { IBlogItem } from 'features/home/services/types/blog';
import { CSSProperties, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import getCreatedDateLabel from 'utils/createdDate';
type Props = {
  sm?: boolean;
  blogId?: string;
  data?: IBlogItem;
  activeLike?: boolean;
  activeDislike?: boolean;
  authInfo?: IAuthUserInfo | null;
  isAuth?: boolean;
  mine?: boolean;
};
const Actions = ({ sm, blogId, data, activeLike, activeDislike, authInfo, isAuth, mine }: Props) => {
  const inputCommentRef = useRef<InputRef>(null);
  const [formComment] = Form.useForm();
  const dispatch = useAppDispatch();
  const [openComment, setOpenComment] = useState(false);
  const [loadingComment, setLoadingComment] = useState(false);

  const onLike = () => {
    if (!blogId) return;
    dispatch(blogActions.like.fetch(blogId));
  };
  const onDislike = () => {
    if (!blogId) return;
    dispatch(blogActions.dislike.fetch(blogId));
  };

  const onOpenComment = () => {
    if (!isAuth) {
      dispatch(authActions.enableGuardLogin(true));
      return;
    }

    const nextValue = !openComment;
    setOpenComment(nextValue);
    if (nextValue) {
      setTimeout(() => inputCommentRef.current?.focus(), 10);
    }
  };

  const handleFinishForm = async (values: any) => {
    if (!blogId) return;
    if (!values.comment) return;
    formComment.resetFields();
    setLoadingComment(true);
    try {
      const res: IResponseDataBody<IBlogItem> = await homeApis.updateActionBlog({
        action: BlogAction.COMMENT, blogId:
          blogId,
        comments: values.comment, dateTime: dayjs().format('MM-DD-YYYY HH:mm:ss')
      });
      const resData = res.data.data;
      if (resData) {
        dispatch(homeActions.updateBlogItem(resData));
      }
    } catch (error) { }
    finally {
      setLoadingComment(false);
    }
  };

  const onReport = () => {
    if (!isAuth) {
      dispatch(authActions.enableGuardLogin(true));
      return;
    }
    if (!data) return;
    dispatch(homeActions.setDraftDataReport(data));
  };

  return (
    <div style={{ width: '100%', zoom: !sm ? 1 : 0.85 }}>
      <Styled sm={sm}>
        <Button type='text' ghost className={`blog-action-item ${activeLike ? 'active' : ''}`} onClick={onLike}>
          <span className="blog-icon"><LikeIcon active={activeLike} /></span>
          <span className="blog-action-name">{data?.totalLike} Likes</span>
        </Button>
        <Button type='text' ghost className={`blog-action-item ${activeDislike ? 'active' : ''}`} onClick={onDislike}>
          <span className="blog-icon"><DislikeIcon active={activeDislike} /></span>
          <span className="blog-action-name">{data?.totalDisLike} Dislikes</span>
        </Button>
        <Button type='text' ghost className={`blog-action-item ${openComment ? 'active' : ''}`} onClick={onOpenComment}>
          <span className="blog-icon"><CommentIcon active={openComment} /></span>
          <span className="blog-action-name">{data?.totalComment} Comments</span>
        </Button>
        {!mine ? <Button type='text' ghost className="blog-action-item" onClick={onReport}>
          <span className="blog-icon"><ReportIcon /></span>
          <span className="blog-action-name"> {data?.totalReport} Reports</span>
        </Button> : null}
      </Styled>
      <WrapCollapse>
        <Collapse
          collapsible="icon"
          expandIconPosition={'end'}
          activeKey={openComment ? '1' : undefined}
          bordered={false}
          items={[{
            key: '1',
            label: null,
            children: <CommentStyled>
              <div className="user-block">
                <Avatar size={32} src={authInfo?.avatar || 'fail'}>
                  <DefaultAvatar />
                </Avatar>
                <Form form={formComment} className='form-comment' onFinish={handleFinishForm}>
                  <Form.Item
                    name={'comment'} noStyle>
                    <Input placeholder='Write your comment'
                      ref={inputCommentRef}
                      suffix={<Button onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          formComment.submit();
                        }
                      }} onClick={e => [e.stopPropagation(), formComment.submit()]} type='primary' ><SendOutlined /></Button>} />
                  </Form.Item>
                </Form>
              </div>
              {loadingComment ? <div style={{ width: '100%', textAlign: 'center' }}><Spin /></div> : null}
              <div className="comments" style={{ opacity: !loadingComment ? 1 : 0 }} >
                {data?.comments?.map(o => (
                  <div key={o.commentId} className='comment-item'>
                    <Avatar size={32} src={o?.avatar || 'fail'}>
                      <DefaultAvatar />
                    </Avatar>
                    <div style={{ borderBottom: '1px solid rgb(128 128 128 / 32%)', width: '100%', paddingBottom: '8px' }}>
                      <div className="user-comment-name">{o.name}</div>
                      <div className="comment-text">{o.note}</div>
                      <div className="comment-create-date">{getCreatedDateLabel(o.createdDate)}</div>
                    </div>
                  </div>
                ))}
              </div>
            </CommentStyled>
          }]}
        />
      </WrapCollapse>
    </div>
  );
};
const WrapCollapse = styled.div`
  .ant-collapse-item .ant-collapse-header {
    display: none;
  }
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0;
      background: transparent;
    }
  }
`;
const CommentStyled = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background: #F2F3F8;
  .ant-avatar {
    border-radius: 100px;
    background: #dde6f4;
    .ant-avatar-string {
      transform: scale(0.8) translateY(3px) !important;
    }
  }
  .user-block {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    .form-comment {
      flex: 1;
    }
    .ant-input-affix-wrapper {
      padding: 2px;
      padding-left: 16px;
      padding-right: 2px;
      height: 36px;
      input {
        color: #0B3558;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &::placeholder {
          color: #808080;
          opacity: 1; /* Firefox */
        }
        
        &::-ms-input-placeholder { /* Edge 12 -18 */
          color: #808080;
        }
      }
      .ant-input-suffix {
        .ant-btn {
          padding: 2px 16px;
          font-size: 16px;
          gap: 0px;
        }
      }
    }
  }
  .comments {
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: column;
    .comment-item {
      display: flex;
      gap: 12px;
      .user-comment-name {
        color: #0B3558;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .comment-text {
        color: #0B3558;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .comment-create-date {
        color: #808080;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;

export const ActionsSkeleton = ({ sm }: Props) => {
  const size: CSSProperties = sm ? {
    height: 20,
  } : {
    height: 25,
  };
  return (
    <Styled sm={sm}>
      <Skeleton.Button active size='small' style={size} />
      <Skeleton.Button active size='small' style={size} />
      <Skeleton.Button active size='small' style={size} />
      <Skeleton.Button active size='small' style={size} />
    </Styled>
  );
};

export default Actions;
const Styled = styled.div<{ sm?: boolean }>`
display: flex;
align-items: center;
gap: 16px;
.blog-action-item {
  display: flex;
  align-items: center;
  &.ant-btn  {
    padding: 0 4px;
    gap: 2px;
  }
  .blog-icon {
    line-height: 1;
  }
  .blog-action-name {
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &.active {
    .blog-action-name {
      color: #006BFF;
    }
  }
}

${({ sm }) => sm ? `
  gap: 0px;
  .blog-action-item {
    flex: 1;
  }

` : ''}
`;
import React from 'react';

const DefaultAvatar = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2766 2.40039H12.7244C14.1014 2.6044 15.3846 3.3962 16.1476 4.56571C16.5823 5.19996 16.8237 5.9458 16.9429 6.70023C17.0909 7.8647 16.8283 9.0832 16.1895 10.0709C15.3967 11.3303 13.9908 12.1817 12.5077 12.2989C12.0356 12.2913 11.5594 12.3317 11.0918 12.2509C10.0909 12.0777 9.14764 11.5868 8.43714 10.8607C7.5494 9.96134 7.04191 8.71305 7.01767 7.45264C6.96717 5.74735 7.85693 4.04408 9.3062 3.13614C9.90005 2.75135 10.5802 2.51249 11.2766 2.40039Z" fill="#808080" />
      <path d="M4.69908 14.9475C5.69438 13.8235 6.96337 12.9408 8.36518 12.4025C9.0883 12.863 9.89221 13.2044 10.7365 13.3604C11.0668 13.4427 11.4071 13.4811 11.748 13.4781C12.101 13.4745 12.456 13.4912 12.8069 13.4467C13.733 13.3043 14.6521 13.0216 15.4434 12.509C15.5247 12.4696 15.611 12.3848 15.707 12.4292C17.7021 13.2119 19.3988 14.7006 20.4704 16.5523C20.9617 17.3542 21.3081 18.2369 21.5758 19.1353C21.801 19.9154 21.9121 20.7209 22.0004 21.5263C21.3046 21.6748 20.6143 21.8454 19.9139 21.9722C19.4922 22.0176 19.0827 22.1388 18.6601 22.1752C18.2217 22.2085 17.7945 22.3242 17.3547 22.3444C16.6301 22.4009 15.9115 22.5221 15.1833 22.5347C14.8682 22.5716 14.546 22.5241 14.2355 22.5994H9.76597C9.45541 22.5246 9.13274 22.5711 8.81763 22.5347C8.03998 22.5196 7.27242 22.3873 6.49779 22.3312C6.10947 22.3009 5.72872 22.2121 5.3409 22.1752C4.91773 22.1398 4.5082 22.0156 4.08604 21.9722C3.38716 21.8404 2.69383 21.6813 2 21.5263C2.0308 21.1249 2.10806 20.7295 2.15503 20.33C2.19694 20.0068 2.30248 19.6963 2.36005 19.3761C2.78574 17.7476 3.56844 16.2009 4.69908 14.9475ZM16.7502 15.6338C16.7442 16.4069 16.7508 17.1805 16.7472 17.9536C15.9736 17.9572 15.2005 17.9511 14.4269 17.9562C14.4238 18.3924 14.4238 18.8292 14.4269 19.2655C15.1995 19.2756 15.9721 19.26 16.7442 19.2731C16.7553 20.0477 16.7412 20.8224 16.7513 21.597C17.2027 21.6101 17.6552 21.6091 18.1071 21.5975C18.1142 20.8557 18.1051 20.1134 18.1101 19.3716C18.1 19.2908 18.1995 19.2605 18.2631 19.2681C18.9858 19.2655 19.7079 19.2731 20.4305 19.2655C20.434 18.8287 20.434 18.3924 20.4305 17.9562C19.6574 17.9511 18.8838 17.9567 18.1101 17.9541C18.1066 17.1805 18.1127 16.4069 18.1076 15.6333C17.6552 15.6302 17.2027 15.6302 16.7502 15.6338Z" fill="#808080" />
      <path d="M20.5 17.5L21 20.5L19 21.5L17 22L15.5 21.5L13.5 20L12.5 17.5L15.5 14.5L18.5 15L20.5 17.5Z" fill="#808080" />
    </svg>
  );
};

export default DefaultAvatar;

import styled from 'styled-components';
import AddNewReview from './components/AddNewReview';
import Banner from './components/Banner';
import FilterReview from './components/FilterReview';
import Footer from './components/Footer';
import Header from './components/Header';
import MostInteraction from './components/MostInteraction';
import WebReviewList from './components/ReviewList/ReviewList.web';
import WriteReviewSection from './components/WriteReviewSection';

const UIWeb = () => {
  return (
    <PageStyled>
      <div className="header">
        <Header />
      </div>
      <Banner />
      <AddNewReview />
      <div style={{ marginBottom: '24px' }} />
      <div className='ContainerWeb'>
        <div style={{ display: 'flex', gap: 16 }}>
          <div style={{ flex: 1 }}>
            <FilterReview />
            <div style={{ marginBottom: '24px' }} />
            <WebReviewList />
            <div style={{ marginBottom: '32px' }} />
          </div>
          <div className='home-MostInteraction' style={{ width: '400px' }}>
            <MostInteraction />
          </div>
        </div>
      </div>
      <div style={{ marginBottom: '24px' }} />
      <WriteReviewSection />
      <Footer />
    </PageStyled>
  );
};

export default UIWeb;
const PageStyled = styled.div`
  .header {
    position: sticky;
    top: 0;
    z-index: 99;
  }
  background: #F2F3F8;
  @media screen and (max-width: 1075px) {
    .home-MostInteraction {
      display: none;
    }
  }
  @media screen and (min-width: 1075px) {
    .home-MostInteraction {
      display: block;
    }
  }
`;

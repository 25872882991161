
import dayjs from 'dayjs';
import { guardLogin } from 'features/auth/services/sagas';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { setLoading } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import { RootState } from 'store';
import actions from './actions';
import apis from './apis';
import { BlogAction, PATH_LOADING } from './constants';
import { IApiGetBlogParams } from './types/api';
import mineActions from 'features/mine/services/actions';
import { IBlogItem } from './types/blog';

const getBlogsMostInteraction = function* () {
  yield setLoading(PATH_LOADING.getBlogsMostInteraction, true);
  try {
    const res: IResponseDataBody<any> = yield call(apis.getBlogsMostInteraction);
    if (res.data.data) {
      yield put(actions.getBlogsMostInteraction.success(res.data.data));
    }
  } catch (error) { } finally {
    yield setLoading(PATH_LOADING.getBlogsMostInteraction, false);
  }
};

const getBlogs: ISagaFunc<IApiGetBlogParams> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getBlogs, true);
  try {
    const res: IResponseDataBody<any> = yield call(apis.getBlogs, payload);
    if (res.data.data) {
      yield put(actions.getBlogs.success(res.data.data));
    }
  } catch (error) { } finally {
    yield setLoading(PATH_LOADING.getBlogs, false);
  }
};

const setBlogParams: ISagaFunc<IApiGetBlogParams> = function* ({ payload }) {
  const storeParams: IApiGetBlogParams = yield select((state: RootState) => state.home.params);

  const _params = { ...storeParams, ...payload };

  if (_params.keySearch === null || _params.keySearch === undefined || _params.keySearch === '') {
    delete _params.keySearch;
  }
  if (_params.sortBy === null || _params.sortBy === undefined || _params.sortBy === '') {
    delete _params.sortBy;
  }

  yield put(actions.getBlogs.fetch(_params));
};

const setBlogsSearchParams: ISagaFunc<IApiGetBlogParams> = function* ({ payload }) {
  const storeParams: IApiGetBlogParams = yield select((state: RootState) => state.home.paramsSearch);

  const _params = { ...storeParams, ...payload };

  if (_params.keySearch === null || _params.keySearch === undefined || _params.keySearch === '') {
    delete _params.keySearch;
  }
  if (_params.sortBy === null || _params.sortBy === undefined || _params.sortBy === '') {
    delete _params.sortBy;
  }

  yield put(actions.getBlogsSearch.fetch(_params));
};

const getBlogsSearch: ISagaFunc<IApiGetBlogParams> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getBlogs, true);
  try {
    const res: IResponseDataBody<any> = yield call(apis.getBlogs, payload);
    if (res.data.data) {
      yield put(actions.getBlogsSearch.success(res.data.data));
    }
  } catch (error) { } finally {
    yield setLoading(PATH_LOADING.getBlogs, false);
  }
};

const homeInitData = function* () {
  yield setLoading(PATH_LOADING.getBlogs, true);
  yield setLoading(PATH_LOADING.getBlogsMostInteraction, true);
  yield delay(200);
  const startDate = dayjs().subtract(1, 'day').format('MM-DD-YYYY');
  const endDate = dayjs().format('MM-DD-YYYY');
  yield all([
    put(actions.setParams({ startDate, endDate, page: 1, size: 10 })),
    put(actions.getBlogsMostInteraction.fetch()),
  ]);
};

const refreshBlogs = function* () {
  const storeParams: IApiGetBlogParams = yield select((state: RootState) => state.home.params);
  const storeParamsSearch: IApiGetBlogParams = yield select((state: RootState) => state.home.paramsSearch);
  const isSearch: boolean = yield select((state: RootState) => state.home.enableSearch);

  const params = isSearch ? storeParamsSearch : storeParams;
  try {
    const res: IResponseDataBody<any> = yield call(apis.getBlogs, params);
    if (res.data.data) {
      yield put(mineActions.getHistoryActions.fetch());
      if (isSearch)
        yield put(actions.getBlogsSearch.success(res.data.data));
      else
        yield put(actions.getBlogs.success(res.data.data));
    }
  } catch (error) { }
};

const afterAddNew = function* () {
  const startDate = dayjs().subtract(1, 'day').format('MM-DD-YYYY');
  const endDate = dayjs().format('MM-DD-YYYY');
  yield put(actions.setParams({ startDate, endDate, page: 1, size: 10 }));
};

const doLikeBlog: ISagaFunc<string> = function* ({ payload }) {
  if (!(yield guardLogin())) return;
  try {
    const res: IResponseDataBody<IBlogItem> = yield call(apis.updateActionBlog, { action: BlogAction.LIKE, blogId: payload, dateTime: dayjs().format('MM-DD-YYYY HH:mm:ss') });
    if (res.data.data) {
      yield put(mineActions.getHistoryActions.fetch());
      yield put(actions.updateBlogItem(res.data.data));
    }
  } catch (error) { }
};

const doDislikeBlog: ISagaFunc<string> = function* ({ payload }) {
  if (!(yield guardLogin())) return;
  try {
    const res: IResponseDataBody<IBlogItem> = yield call(apis.updateActionBlog, { action: BlogAction.DISLIKE, blogId: payload, dateTime: dayjs().format('MM-DD-YYYY HH:mm:ss') });
    if (res.data.data) {
      yield put(mineActions.getHistoryActions.fetch());
      yield put(actions.updateBlogItem(res.data.data));
    }
  } catch (error) { }
};

const doCommentBlog: ISagaFunc<{ blogId: string, comment: string }> = function* ({ payload }) {
  if (!(yield guardLogin())) return;
  try {
    const res: IResponseDataBody<boolean> = yield call(apis.updateActionBlog, { action: BlogAction.COMMENT, blogId: payload.blogId, comments: payload.comment, dateTime: dayjs().format('MM-DD-YYYY HH:mm:ss') });
    if (res.data.data) yield refreshBlogs();
  } catch (error) { }
};

export default function* homeServiceSagas() {
  yield takeLatest(actions.init, homeInitData);
  yield takeLatest(actions.setParams, setBlogParams);
  yield takeLatest(actions.getBlogs.fetch, getBlogs);
  yield takeLatest(actions.setBlogsSearchParams, setBlogsSearchParams);
  yield takeLatest(actions.getBlogsSearch.fetch, getBlogsSearch);
  yield takeLatest(actions.getBlogsMostInteraction.fetch, getBlogsMostInteraction);
  yield takeLatest(actions.blogActions.like.fetch, doLikeBlog);
  yield takeLatest(actions.blogActions.dislike.fetch, doDislikeBlog);
  yield takeLatest(actions.blogActions.doComment.fetch, doCommentBlog);
  yield takeLatest(actions.afterAddNew, afterAddNew);
}

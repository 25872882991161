import { AutoComplete, Empty, Form, Input, InputRef } from 'antd';
import { RcFile } from 'antd/es/upload';
import InputPhone from 'components/InputPhone';
import { useMemo, useRef } from 'react';
import mineSelectors from 'features/mine/services/selectors';
import { IBanlistCustomerItem } from 'features/mine/services/types/customer';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import { useSMLayout } from 'utils/withLayout';

export interface IFormInfoValues {
  images: IImageItem[];
  customerName: string;
  customerPhone: string;
  customerAddress: string;
  customerData: IBanlistCustomerItem | null;
  city: string;
  state: string;
  zipCode: string;
  content: string;
}

interface IImageItem {
  uid: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  percent: number;
  originFileObj: RcFile;
  thumbUrl: string;
}

const FormInfo = () => {
  const sm = useSMLayout();
  return (
    <FormInfoStyled sm={sm}>
      <Form.Item name='customerName' label={<>{'Customer Name '} <span className='require-symbol'>(***)</span></>} rules={[{
        required: true, message: 'Please enter customer Name'
      }]}>
        <InputName />
      </Form.Item>
      <Form.Item name='customerPhone' label="Customer Phone" className='phone-input-container'>
        <InputPhone className="phone-input" placeholder='(***) ***-****' />
      </Form.Item>
      <Form.Item name='customerAddress' label="Customer Address">
        <Input placeholder='Address' />
      </Form.Item>
      <Form.Item name='city' label="City">
        <Input placeholder='Enter city' />
      </Form.Item>
      <Form.Item name='state' label="State">
        <Input placeholder='Enter state' />
      </Form.Item>
      <Form.Item name='zipCode' label="Zip Code">
        <Input placeholder='Enter Zip Code' />
      </Form.Item>
      <Form.Item name='customerData' noStyle />
      <div className="content-input">
        <Form.Item name='content' label={<>{'Content '} <span className='require-symbol'>(***)</span></>} rules={[{
          required: true, message: 'Please enter review content'
        }]}>
          <Input.TextArea
            rows={7}
            className='text-area'
            placeholder='Enter review content'
          />
        </Form.Item>
      </div>
    </FormInfoStyled>
  );
};

export default FormInfo;
type InputNameProps = {
  value?: string;
  onChange?: (val: string) => void;
};

const InputName = ({ value: searchText, onChange: setSearchText = () => undefined }: InputNameProps) => {
  const inputRef = useRef<InputRef>(null);
  const form = Form.useFormInstance();
  const customerStore = mineSelectors.searchCustomers();
  // const [customersFilter, setCustomersFilter] = useState<IBanlistCustomerItem[]>([]);
  // const [loading, setLoading] = useState(false);

  const initialOptions = useMemo(() => customerStore.map(o => {
    return ({ value: o.id, name: o.name, label: renderLabel(o.name, formatPhone(o.phone)) });
  }), [customerStore]);


  // const optionsFilter = useMemo(() => customersFilter.map(o => {
  //   return ({
  //     value: o.id,
  //     name: o.name,
  //     label: renderLabel(o.name, formatPhone(o.phone)),
  //   });
  // }), [customersFilter]);

  const options = useMemo(() => {
    const _search = searchText?.trim().toLowerCase();
    if (!_search) return initialOptions;
    return initialOptions.filter(o => o.name.toLowerCase().includes(_search));
  }, [initialOptions, searchText]);

  const onSelect = (o: IBanlistCustomerItem) => {
    form.setFieldsValue({
      customerData: o,
      customerPhone: formatPhone(o.phone),
      customerAddress: o.address,
      city: o.city,
      state: o.state,
      zipCode: o.zip || '',
    });
    setSearchText(o.name);
  };

  const handleSwitchCustomer = (data: string) => {
    inputRef.current?.blur();
    setSearchText('');
    const result = customerStore?.find((item) => item.id === data);
    if (!result) return;
    onSelect(result);
  };

  // const getList = useCallback(debounce(async (search: string) => {
  //   try {
  //     const res: AxiosResponse<{ data: IBanlistCustomerItem[] }> = await banlistApis.blog.searchCustomer(search);
  //     if (res.data.data) {
  //       setCustomersFilter(res.data.data);
  //     }
  //   } catch (error) { }
  //   finally {
  //     setLoading(false);
  //   }
  // }, 500), []);

  return (
    <AutoComplete
      style={{ width: '100%' }}
      options={options}
      // dropdownRender={(menu) => (<>
      //   {searchText && loading ? <Row align={'middle'} justify={'center'}><div style={{ padding: '1.5rem 0' }}><Spin /></div></Row> : menu}
      // </>)}
      notFoundContent={<Empty />}
      onSelect={handleSwitchCustomer}
      value={searchText}
      maxLength={11}
      onChange={text => {
        // if (text) setLoading(true);
        setSearchText(text);
        // getList(text);
      }}
    >
      <Input ref={inputRef} placeholder='Enter Name' />
    </AutoComplete>
  );
};

const renderLabel = (name: string, tel: string) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <p>
        {name}
      </p>
    </div>
    <div>
      <p>
        {tel}
      </p>
    </div>
  </div>
);

const FormInfoStyled = styled.div<{ sm?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  .content-input {
    grid-column: 1 / span 2;
  }

  ${({ sm }) => sm ? `
  display: flex;
  flex-direction: column;
  gap: 0px;
  .ant-form-item {
    margin-bottom: 16px;
  }
  `: ''}

  .phone-input-container {
    .ant-form-item-control-input-content {
      display: flex;
    }
  }

  .ant-input {
    padding: 0;
    border-radius: 5px;
    border: 1px solid #E7EDF6;
    background: #F2F3F8;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #1D2129;
    &::placeholder {
      color: #808080;
      opacity: 1; /* Firefox */
    }
    
    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: #808080;
    }

    &:not(.text-area) {
      height: 45px;
      padding: 0 16px;
    }

    &.text-area {
      min-height: 120px;
      padding: 16px;
    }
  }

  .ant-form-item-label {
    padding: 0;
    label {
      color: #0B3558;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .require-symbol {
      color: #DF0D0D;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-left: 4px;
    }
  }

  .ant-select {
    padding: 0;
    border-radius: 5px;
    border: 1px solid #E7EDF6;
    background: #F2F3F8;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 45px;
    .ant-select-selection-placeholder, .ant-select-selection-search-input {
      color: #1D2129;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .ant-select-selection-placeholder {
      color: #808080; 
    }
    .ant-select-selector {
      border: none;
      background: none;
      .ant-select-selection-search {

      }
    }
  }
`;
import Select from 'components/Select';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
import homeActions from '../services/actions';
import { BlogAction } from '../services/constants';
import homeSelectors from '../services/selectors';
const FORMAT_DATE = 'MM-DD-YYYY';
const sortByOpts = [
  { label: 'Like', value: BlogAction.LIKE },
  { label: 'Dislike', value: BlogAction.DISLIKE },
  { label: 'Comment', value: BlogAction.COMMENT },
];

const FilterReview = () => {
  const dispatch = useAppDispatch();
  const sm = useSMLayout();
  const params = homeSelectors.getParams();
  const enableSearch = homeSelectors.getEnableSearch();
  const handleSortBy = (value: BlogAction) => {
    if (enableSearch) return dispatch(homeActions.setBlogsSearchParams({ sortBy: value, page: 1 }));
    dispatch(homeActions.setParams({ sortBy: value, page: 1 }));
  };

  const getDateValue = (startDate: string, endDate: string) => {
    return `${startDate}_${endDate}`;
  };

  const dateOpts = useMemo(() => {
    const now = dayjs();
    const yesterday = now.clone().subtract(1, 'day');
    return [
      { label: 'Last 24 hours', value: getDateValue(yesterday.format(FORMAT_DATE), now.format(FORMAT_DATE)) },
      { label: 'Last 7 days', value: getDateValue(now.clone().subtract(7, 'day').format(FORMAT_DATE), now.format(FORMAT_DATE)) },
      { label: 'Last 14 days', value: getDateValue(now.clone().subtract(14, 'day').format(FORMAT_DATE), now.format(FORMAT_DATE)) },
      { label: 'Last 30 days', value: getDateValue(now.clone().subtract(30, 'day').format(FORMAT_DATE), now.format(FORMAT_DATE)) },
      { label: 'Last 90 days', value: getDateValue(now.clone().subtract(90, 'day').format(FORMAT_DATE), now.format(FORMAT_DATE)) },
    ];
  }, []);
  const handleFilterDate = (value: string) => {
    const [startDate = '', endDate = ''] = value?.split('_') || [];
    if (!startDate || !endDate) return;
    if (enableSearch) return dispatch(homeActions.setBlogsSearchParams({ startDate, endDate, page: 1 }));
    dispatch(homeActions.setParams({ startDate, endDate, page: 1 }));
  };

  if (sm) return (
    <AppStyled>
      <Select
        placeholder="Date"
        value={getDateValue(params.startDate || '', params.endDate || '')}
        options={dateOpts}
        onChange={handleFilterDate}
      />
      <Select
        placeholder="Sort by"
        value={params.sortBy}
        options={sortByOpts}
        onChange={handleSortBy}
      />
    </AppStyled>
  );

  return (
    <WebStyled>
      <Select
        placeholder="Date"
        value={getDateValue(params.startDate || '', params.endDate || '')}
        options={dateOpts}
        onChange={handleFilterDate}
      />
      <Select
        placeholder="Sort by"
        value={params.sortBy}
        options={sortByOpts}
        onChange={handleSortBy}
      />
    </WebStyled>
  );
};

export default FilterReview;
const Styled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const AppStyled = styled(Styled)`
  padding-right: 16px;
  padding-left: 16px;
`;
const WebStyled = styled(Styled)``;

import React from 'react';

const EmailPrefix = () => {
  return (
    <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_4009_23194" fill="white">
        <path d="M0 0H38V26H0V0Z" />
      </mask>
      <path d="M37 0V26H39V0H37Z" fill="#E7EDF6" mask="url(#path-1-inside-1_4009_23194)" />
      <path d="M23.5938 8.1875H14.4062C14.0003 8.18793 13.611 8.3494 13.324 8.63647C13.0369 8.92354 12.8754 9.31277 12.875 9.71875V16.2812C12.8754 16.6872 13.0369 17.0765 13.324 17.3635C13.611 17.6506 14.0003 17.8121 14.4062 17.8125H23.5938C23.9997 17.8121 24.389 17.6506 24.676 17.3635C24.9631 17.0765 25.1246 16.6872 25.125 16.2812V9.71875C25.1246 9.31277 24.9631 8.92354 24.676 8.63647C24.389 8.3494 23.9997 8.18793 23.5938 8.1875ZM23.206 10.7204L19.2685 13.7829C19.1917 13.8425 19.0973 13.8749 19 13.8749C18.9027 13.8749 18.8083 13.8425 18.7315 13.7829L14.794 10.7204C14.7477 10.6854 14.7089 10.6416 14.6797 10.5916C14.6505 10.5415 14.6315 10.4861 14.6239 10.4286C14.6163 10.3711 14.6202 10.3127 14.6354 10.2568C14.6505 10.2008 14.6767 10.1484 14.7123 10.1027C14.7478 10.0569 14.7922 10.0187 14.8427 9.99019C14.8931 9.9617 14.9488 9.94353 15.0064 9.93674C15.0639 9.92994 15.1223 9.93466 15.178 9.95061C15.2337 9.96656 15.2858 9.99342 15.331 10.0296L19 12.8832L22.669 10.0296C22.7607 9.96037 22.8761 9.92998 22.99 9.94506C23.104 9.96014 23.2075 10.0195 23.278 10.1102C23.3486 10.201 23.3806 10.3159 23.3671 10.4301C23.3536 10.5442 23.2958 10.6485 23.206 10.7204Z" fill="#808080" />
    </svg>
  );
};

export default EmailPrefix;

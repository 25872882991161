import BlogLG from 'components/Blog/BlogLG';
import BlogSM from 'components/Blog/BlogSM';
import { useModalDelete } from 'components/ModalDelete';
import authSelectors from 'features/auth/services/selectors';
import { IBlogItem } from 'features/home/services/types/blog';
import mineActions from 'features/mine/services/actions';
import mineApis from 'features/mine/services/apis';
import mineSelectors from 'features/mine/services/selectors';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import ReviewListMobileUI from './ReviewListMobileUI';
import ReviewListWebUI from './ReviewListWebUI';
import { useSMLayout } from 'utils/withLayout';

const DraftReviews = () => {
  const sm = useSMLayout();
  const dispatch = useAppDispatch();
  const [modalDeleteRef, modalDeleteContext] = useModalDelete();

  const loading = mineSelectors.getDraftBlogLoading();
  const data = mineSelectors.getDraftBlogs();
  const paging = mineSelectors.getDraftPaging();
  const params = mineSelectors.getDraftParams();
  const historyActions = mineSelectors.historyActions();

  const authInfo = mineSelectors.authInfo();
  const isAuth = authSelectors.isAuth();

  const setLoading = useSetLoadingPage();

  const onChangePage = (page: number) => {
    dispatch(mineActions.setParams({ page }));
  };
  const onDelete = (id: string) => () => {
    const callback = async () => {
      setLoading(true);
      try {
        const res: IResponseDataBody<boolean> = await mineApis.deleteBlog(id);
        if (res.data.data) {
          dispatch(mineActions.setDraftParams({ page: 1 }));
        }
      } catch (error) { }
      finally {
        setLoading(false);
      }
    };
    modalDeleteRef.current?.open({ title: 'Delete Your Review', message: 'Are you sure you want to delete the selected review post?' }, callback);
  };

  const renderItem = (o: IBlogItem) => (
    <BlogLG
      key={o.id}
      data={o}
      historyActions={historyActions}
      authInfo={authInfo}
      isAuth={isAuth}
      mine
      draft
      onDelete={onDelete(o.id)}
    />
  );
  const renderSMItem = (o: IBlogItem) => (
    <BlogSM
      key={o.id}
      data={o}
      historyActions={historyActions}
      authInfo={authInfo}
      isAuth={isAuth}
      mine
      draft
      onDelete={onDelete(o.id)}
    />
  );

  if (sm) {
    return (
      <>
        <ReviewListMobileUI
          loading={loading}
          data={data}
          onChangePage={onChangePage}
          params={params}
          renderItem={renderSMItem}
          totalElements={paging.totalElements}
        />
        {modalDeleteContext}
      </>
    );
  }

  return (
    <>
      <ReviewListWebUI
        loading={loading}
        data={data}
        onChangePage={onChangePage}
        params={params}
        renderItem={renderItem}
        totalElements={paging.totalElements}
      />
      {modalDeleteContext}
    </>
  );
};

export default DraftReviews;

import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';
import { IBlogItem } from './types/blog';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['home']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['home']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getBlogs = () => useAppSelector((root: RootState) => {
  const state = root.home;
  if (state.enableSearch) return state.dataSearch;
  return state.data;
});

const getParams = () => useAppSelector((root: RootState) => {
  const state = root.home;
  if (state.enableSearch) return state.paramsSearch;
  return state.params;
});

const getPaging = () => useAppSelector((root: RootState) => {
  const state = root.home;
  if (state.enableSearch) return state.pagingSearch;
  return state.paging;
});

const getBlogLoading = () => uiSelector.getLoading(PATH_LOADING.getBlogs) as boolean;
const getEnableSearch = () => useAppSelector((root: RootState) => root.home.enableSearch);

const getMostInteraction = () => selector('mostInteraction') as IBlogItem[];
const getMostInteractionLoading = () => uiSelector.getLoading(PATH_LOADING.getBlogsMostInteraction) as boolean;

const draftDataReport = () => selector('draftDataReport') as IBlogItem | null;
const homeSelectors = {
  getBlogs,
  getBlogLoading,
  getMostInteraction,
  getMostInteractionLoading,
  getParams,
  getPaging,
  getEnableSearch,
  draftDataReport,
};
export default homeSelectors;

export const NAME_REDUCER = 'home';

export const PREFIX_ACTIONS = 'home_feature_';


export const PATH_LOADING = {
  getBlogs: `loading.${NAME_REDUCER}.getBlogs`,
  getBlogsMostInteraction: `loading.${NAME_REDUCER}.getBlogsMostInteraction`,
};

export enum BlogAction {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
  COMMENT = 'COMMENT',
  REPORT = 'REPORT'
}

import styled from 'styled-components';
import FormSignUp from './components/FormSignUp';
import Header from '../../components/Header';
import Footer from './components/Footer';

const UIWebSignUpPage = () => {
  return (
    <Styled>
      <Header />
      <div style={{ marginBottom: '32px' }} />
      <div className="ContainerWeb large box-form">
        <FormSignUp />
        <div style={{ marginBottom: '16px' }} />
        <Footer />
      </div>
    </Styled>
  );
};

export default UIWebSignUpPage;
const Styled = styled.div`
background:#fff;
min-height: 100vh;
.box-form {
  border-radius: 5px;
  background: #F2F3F8;
  display: flex;
  max-width: 1140px;
  padding: 24px 40px;
  flex-direction: column;
  align-items: center;
}
`;

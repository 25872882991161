import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiGetDemoDataParam } from './types/api';
import { IUserSignUpInfo } from './types/auth';

const getDemoData = createAsyncAction<IApiGetDemoDataParam>(PREFIX_ACTIONS + 'getDemoData');
const enableGuardLogin = createAction<boolean>(PREFIX_ACTIONS + 'enableGuardLogin');
const logout = createAction(PREFIX_ACTIONS + 'logout');
const setUserSignUpInfo = createAction<IUserSignUpInfo | null>(PREFIX_ACTIONS + 'setUserSignUpInfo');
const authActions = {
  getDemoData,
  enableGuardLogin,
  logout,
  setUserSignUpInfo,
};

export default authActions;

import { IApiGetBlogParams, IBodyApiUpdateInfo, IBodyApiUpdatePassword } from 'features/home/services/types/api';
import fetch from 'services/request';

const getHistoryActions = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/ban-list/users/history-action',
  });
};
const getRefreshToken = (refreshToken: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/ban-list/auth/refresh-token',
    body: { refreshToken }
  });
};

const getSearchCustomers = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/admin/ban-customers/search',
    params: { keySearch: '' }
  });
};

const getDetailInfo = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/ban-list/users/user-detail',
  });
};

const getBlogs = (params: IApiGetBlogParams) => {
  return fetch({
    method: 'get',
    url: '/api/v1/ban-list/users/blogs',
    params,
  });
};
const deleteBlog = (blogId: string) => {
  return fetch({
    method: 'delete',
    url: `/api/v1/ban-list/users/delete-blog/${blogId}`,
  });
};

const updateAvatar = (body: FormData) => {
  return fetch({
    method: 'post',
    url: '/api/v1/ban-list/users/update-avatar',
    body,
  });
};

const updateInfo = (body: IBodyApiUpdateInfo) => {
  return fetch({
    method: 'post',
    url: '/api/v1/ban-list/users/update-info',
    body,
  });
};

const updatePassword = (body: IBodyApiUpdatePassword) => {
  return fetch({
    method: 'post',
    url: '/api/v1/ban-list/users/update-password',
    body,
  });
};

const mineApis = {
  getHistoryActions,
  getSearchCustomers,
  getRefreshToken,
  getDetailInfo,
  getBlogs,
  deleteBlog,
  updateAvatar,
  updateInfo,
  updatePassword,
};

export default mineApis;

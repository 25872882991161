import React from 'react';

const UserNamePrefix = () => {
  return (
    <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_4009_21419" fill="white">
        <path d="M0 0H38V26H0V0Z" />
      </mask>
      <path d="M37 0V26H39V0H37Z" fill="#E7EDF6" mask="url(#path-1-inside-1_4009_21419)" />
      <path d="M18.5793 7.1084H19.4238C20.2271 7.2274 20.9756 7.68929 21.4207 8.3715C21.6743 8.74148 21.8151 9.17656 21.8847 9.61664C21.971 10.2959 21.8178 11.0067 21.4452 11.5829C20.9827 12.3175 20.1626 12.8142 19.2975 12.8825C19.0221 12.8781 18.7443 12.9017 18.4715 12.8545C17.8877 12.7535 17.3374 12.4672 16.923 12.0436C16.4051 11.519 16.1091 10.7908 16.0949 10.0555C16.0655 9.06079 16.5845 8.06721 17.4299 7.53758C17.7763 7.31312 18.1731 7.17379 18.5793 7.1084Z" fill="#808080" />
      <path d="M14.7415 14.4275C15.322 13.7718 16.0623 13.2569 16.88 12.9429C17.3018 13.2115 17.7708 13.4106 18.2633 13.5017C18.4559 13.5497 18.6545 13.5721 18.8533 13.5703C19.0592 13.5682 19.2663 13.578 19.471 13.552C20.0113 13.469 20.5474 13.304 21.009 13.005C21.0564 12.982 21.1068 12.9326 21.1627 12.9585C22.3266 13.4151 23.3163 14.2834 23.9414 15.3636C24.228 15.8314 24.4301 16.3463 24.5862 16.8703C24.7176 17.3254 24.7824 17.7953 24.8339 18.2651C24.428 18.3517 24.0253 18.4513 23.6168 18.5252C23.3708 18.5517 23.1319 18.6224 22.8854 18.6436C22.6297 18.6631 22.3805 18.7305 22.1239 18.7423C21.7012 18.7753 21.282 18.846 20.8573 18.8534C20.6734 18.8749 20.4855 18.8472 20.3044 18.8911H17.6971C17.516 18.8475 17.3278 18.8746 17.1439 18.8534C16.6903 18.8445 16.2426 18.7673 15.7907 18.7347C15.5642 18.717 15.3421 18.6651 15.1158 18.6436C14.869 18.623 14.6301 18.5505 14.3838 18.5252C13.9762 18.4483 13.5717 18.3555 13.167 18.2651C13.185 18.0309 13.23 17.8003 13.2574 17.5673C13.2819 17.3788 13.3434 17.1976 13.377 17.0108C13.6253 16.0609 14.0819 15.1586 14.7415 14.4275ZM21.7713 14.8278C21.7678 15.2788 21.7716 15.7301 21.7695 16.181C21.3183 16.1831 20.8673 16.1796 20.416 16.1825C20.4142 16.437 20.4142 16.6918 20.416 16.9463C20.8667 16.9522 21.3174 16.9431 21.7678 16.9508C21.7742 17.4026 21.766 17.8545 21.7719 18.3064C22.0352 18.314 22.2992 18.3134 22.5628 18.3066C22.5669 17.8739 22.5616 17.4409 22.5646 17.0082C22.5587 16.9611 22.6167 16.9434 22.6538 16.9478C23.0754 16.9463 23.4966 16.9508 23.9181 16.9463C23.9202 16.6915 23.9202 16.437 23.9181 16.1825C23.4671 16.1796 23.0158 16.1828 22.5646 16.1813C22.5625 15.7301 22.566 15.2788 22.5631 14.8275C22.2992 14.8257 22.0352 14.8257 21.7713 14.8278Z" fill="#808080" />
      <path d="M23.9587 15.9165L24.2503 17.6665L23.0837 18.2498L21.917 18.5415L21.042 18.2498L19.8753 17.3748L19.292 15.9165L21.042 14.1665L22.792 14.4582L23.9587 15.9165Z" fill="#808080" />
    </svg>
  );
};

export default UserNamePrefix;

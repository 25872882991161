import { Button, Carousel, Modal } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import { createRef, useState } from 'react';
import uiActions from 'services/UI/actions';
import uiSelector from 'services/UI/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
export const viewImagesModalCarouselRef = createRef<CarouselRef>();
const ViewImagesModal = () => {
  const dispatch = useAppDispatch();
  const [currentSlide, setCurrentSlide] = useState(1);
  const visible = uiSelector.openImageView();
  const images = uiSelector.getImagesView();
  const sm = useSMLayout();
  const onClose = () => {
    dispatch(uiActions.closeImageView());
  };

  if (sm) return (
    <Modal
      open={visible && !!images.length}
      onCancel={onClose}
      forceRender
      footer={null}
      closeIcon={null}
      closable={false}
      width={'100vw'}
      centered
      wrapClassName='modal-non-style modal-images'
    >
      <SMCarousel className={images?.length === 1 ? 'single' : ''}>
        <Carousel
          ref={viewImagesModalCarouselRef}
          useTransform={false}
          beforeChange={(prev, next) => setCurrentSlide(next)}
          customPaging={(index) => {
            const image = images?.[index];
            return (
              <div className='slick-dot-item'>
                <div className='slick-dot-item-overlay' style={{ backgroundImage: `url(${image})` }} />
                {index === currentSlide && <div className="slick-dot-item-overlay"><IconTick /></div>}
              </div>
            );
          }}
        >
          {images.map((o, i) => (
            <div key={i} className='carousel-item'>
              <div className='image-holder' style={{ backgroundImage: `url(${o})` }}></div>
            </div>
          ))}
        </Carousel>
        <Button onClick={onClose} ghost className='btn-close'><IconClose /></Button>
      </SMCarousel>
    </Modal>
  );

  return (
    <Modal
      open={visible && !!images.length}
      onCancel={onClose}
      forceRender
      footer={null}
      closeIcon={null}
      closable={false}
      width={'100vw'}
      centered
      wrapClassName='modal-non-style modal-images'
    >
      <ViewImagesModalStyled className={images?.length === 1 ? 'single' : ''}>
        <Button onClick={onClose} ghost className='btn-close'><IconClose /></Button>
        <Carousel
          ref={viewImagesModalCarouselRef}
          beforeChange={(prev, next) => setCurrentSlide(next)}
          useTransform={false}
          customPaging={(index) => {
            const image = images?.[index];
            return (
              <div className='slick-dot-item'>
                <img src={image} />
                {index === currentSlide && <div className="slick-dot-item-overlay"><IconTick /></div>}
              </div>
            );
          }}
        >
          {images.map((o, i) => {
            return (<div key={i + 'carousel-item'} className='carousel-item'>
              <div className="image-item">
                <img src={o} alt="" />
              </div>
            </div>);
          })}
        </Carousel>
      </ViewImagesModalStyled>
    </Modal>
  );
};

export default ViewImagesModal;

const SMCarousel = styled.div`
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .btn-close {
    border: none;
  }
  .ant-carousel {
    margin-bottom: 32px;
    .slick-dots-bottom {
      height: 64px;
      overflow-x: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 0px;
      }

      .slick-dot-item {
        height: 100px;
        width: 100px;
        position: relative;
        .slick-dot-item-overlay {
          position: absolute;
          inset: 0;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
    .slick-dot-item-overlay {
      position: absolute;
      inset: 0;
      background: rgba(0,0,0,0.6);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .slick-dots {
      height: 65px;
      bottom: 0;
      padding: 24px 0;
      position: relative;
      li {
        width: unset !important;
        text-indent: unset;
      }  
    }
  }
  .carousel-item {
    background: #fff;
    position: relative;
    height: calc(100vh - 300px);
    .image-holder {
      position: absolute;
      inset: 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &.single {
    .ant-carousel {
      .slick-slider {
        .slick-list {
          height: 100%;
        }
        .slick-track {
          height: 100%;
        }
      }
      .slick-dots {
        display: none !important;
      }
    }
  }
`;

const ViewImagesModalStyled = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  .slick-dot-item {
    width: 148px;
    height: 148px;
    position: relative;
    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .slick-dot-item-overlay {
      position: absolute;
      inset: 0;
      background: rgba(0,0,0,0.6);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btn-close {
    position: absolute;
    right: 32px;
    top: 32px;
    border: none;
    z-index: 9999;
  }
  .ant-carousel {
    flex: 1;
    height: 100%;
    .slick-slider {
      padding-top: 5vh;
      height: 100%;
      .slick-slide {
        width: 100%;
        div {
          height: 100%;
        }
      }
      .slick-list {
        height: calc(100% - 24vh);
      }
      .slick-track {
        height: 100%;
      }
    }
    .slick-dots {
      height: 148px;
      bottom: 0;
      padding: 24px 0;
      li {
        width: unset !important;
        text-indent: unset;
      }  
    }
  }
  .carousel-item {

  }
  .image-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.single {
    .ant-carousel {
      .slick-slider {
        .slick-list {
          height: 100%;
        }
        .slick-track {
          height: 100%;
        }
      }
      .slick-dots {
        display: none !important;
      }
    }
  }
`;

const IconClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M30.2735 5.72541C30.9514 6.40327 30.9514 7.50229 30.2735 8.18015L8.18089 30.2728C7.50304 30.9506 6.40401 30.9506 5.72616 30.2728C5.0483 29.5949 5.0483 28.4959 5.72616 27.818L27.8188 5.72541C28.4966 5.04756 29.5957 5.04756 30.2735 5.72541Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.72649 5.72541C6.40435 5.04756 7.50337 5.04756 8.18123 5.72541L30.2738 27.818C30.9517 28.4959 30.9517 29.5949 30.2738 30.2728C29.596 30.9506 28.497 30.9506 27.8191 30.2728L5.72649 8.18015C5.04863 7.50229 5.04863 6.40327 5.72649 5.72541Z" fill="white" />
  </svg>
);

const IconTick = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none">
    <path d="M28.5539 0.507486C28.9073 0.325581 29.3311 0.417962 29.5739 0.733201C28.2825 2.0313 26.9016 3.23796 25.6758 4.60177C22.9673 7.41034 20.5663 10.5142 18.5035 13.8246C17.8806 15.0151 17.0854 16.1037 16.4549 17.2894C15.7235 18.5161 14.9673 19.7275 14.2158 20.9418C14.1016 21.2103 13.7758 21.1237 13.5482 21.1113C13.2654 20.7503 13.073 20.3313 12.8463 19.9361C12.3854 18.9694 11.8797 18.0199 11.5282 17.0065C11.213 16.058 10.7416 15.1722 10.2854 14.2865C9.94727 13.6332 9.49203 13.0142 8.85965 12.6199C8.46251 12.3627 7.97203 12.4303 7.52441 12.4332C7.6387 11.6122 8.31394 11.0018 9.04822 10.6989C9.55584 10.4599 10.1358 10.4894 10.6797 10.5427C11.7254 10.7913 12.5063 11.6132 13.1216 12.4475C13.4635 12.9342 13.7987 13.4284 14.0711 13.958C14.1187 13.958 14.2139 13.9599 14.2606 13.9599C15.0425 12.7608 15.9492 11.6484 16.7977 10.4961C17.6625 9.39225 18.573 8.32463 19.5206 7.2913C20.3616 6.42177 21.1473 5.49415 22.0663 4.70272C23.9425 2.9151 26.1768 1.52463 28.5539 0.507486Z" fill="white" />
    <path d="M4.12153 5.23363C6.73201 2.49554 10.5396 0.959348 14.3168 1.09744C17.0292 1.21268 19.712 2.14697 21.8844 3.7803C21.9815 3.93649 21.7596 4.00887 21.6787 4.10887C21.2825 4.40411 20.9872 4.81649 20.5968 5.11173C20.3882 5.03078 20.2149 4.88221 20.0234 4.76982C17.0806 2.88506 13.2682 2.44982 9.97963 3.63268C7.33391 4.54792 5.07105 6.47363 3.68725 8.89935C2.41963 11.1413 1.89963 13.8136 2.31201 16.3603C2.68343 18.907 3.96153 21.2965 5.81963 23.0698C7.96058 25.0889 10.8844 26.3146 13.8415 26.2479C16.5472 26.3041 19.2301 25.2698 21.2968 23.5413C23.3349 21.8365 24.7691 19.4184 25.2577 16.8041C25.4987 15.7365 25.4349 14.6336 25.413 13.5479C25.2539 11.6089 24.5406 9.74506 23.4977 8.11268C23.8463 7.58411 24.2996 7.13554 24.7215 6.66601C25.9701 8.35268 26.8272 10.3317 27.1501 12.4079C27.7368 15.8127 26.9291 19.4232 24.9625 22.2632C23.372 24.5813 21.0444 26.3898 18.3968 27.3412C15.3577 28.4527 11.9206 28.3984 8.9101 27.2165C7.26439 26.547 5.71391 25.6003 4.45677 24.3365C2.65772 22.6374 1.3701 20.4108 0.74534 18.0203C0.30534 16.4289 0.273911 14.7593 0.392959 13.1232C0.716769 10.1774 2.04248 7.34792 4.12153 5.23363Z" fill="white" />
  </svg>
);

import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import authActions from 'features/auth/services/actions';
import { IApiGetBlogParams } from 'features/home/services/types/api';
import homeActions from 'features/home/services/actions';

const initialState: IState = {
  dataDemo: [],
  authInfo: null,
  historyActions: [],
  searchCustomers: [],
  data: [],
  paging: {
    totalElements: 0,
    totalPage: 0,
  },
  params: {},

  draftData: [],
  draftPaging: {
    totalElements: 0,
    totalPage: 0,
  },
  draftParams: {},
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.setDraftParams, (state, { payload }) => {
        const storeParams = state.draftParams;
        const _params: IApiGetBlogParams = {
          ...storeParams,
          ...payload,
        };

        if (_params.keySearch === null || _params.keySearch === undefined || _params.keySearch === '') {
          delete _params.keySearch;
        }
        if (_params.sortBy === null || _params.sortBy === undefined || _params.sortBy === '') {
          delete _params.sortBy;
        }

        state.draftParams = _params;
      })
      .addCase(actions.getDraftBlogs.success, (state, { payload }) => {
        state.draftData = payload.content;
        state.draftPaging.totalElements = payload.totalElements;
        state.draftPaging.totalPage = payload.totalPages;
      })
      .addCase(actions.setParams, (state, { payload }) => {
        const storeParams = state.params;
        const _params: IApiGetBlogParams = {
          ...storeParams,
          ...payload,
        };

        if (_params.keySearch === null || _params.keySearch === undefined || _params.keySearch === '') {
          delete _params.keySearch;
        }
        if (_params.sortBy === null || _params.sortBy === undefined || _params.sortBy === '') {
          delete _params.sortBy;
        }

        state.params = _params;
      })
      .addCase(homeActions.updateBlogItem, (state, { payload }) => {
        const idxDraft = state.draftData.findIndex(o => o.id === payload.id);
        if (idxDraft !== -1) {
          state.draftData[idxDraft] = { ...payload };
        }
        const idx = state.data.findIndex(o => o.id === payload.id);
        if (idx !== -1) {
          state.data[idx] = { ...payload };
        }
      })
      .addCase(actions.getBlogs.success, (state, { payload }) => {
        state.data = payload.content;
        state.paging.totalElements = payload.totalElements;
        state.paging.totalPage = payload.totalPages;
      })
      .addCase(actions.getDetailInfo.success, (state, { payload }) => {
        state.authInfo = payload;
      })
      .addCase(actions.getHistoryActions.success, (state, { payload }) => {
        state.historyActions = payload;
      })
      .addCase(authActions.logout, (state) => {
        state.authInfo = null;
      })
      .addCase(actions.setAuthUserInfo, (state, { payload }) => {
        state.authInfo = payload;
      })
      .addCase(actions.getSearchCustomers.success, (state, { payload }) => {
        state.searchCustomers = payload;
      })
      ;
  },
});

const mineServiceReducer = Slice.reducer;
export default mineServiceReducer;

import { Modal } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
import Button from './Button';
type ModalDeleteRef = {
  open: (configs: { title: string, message: string }, callback: () => void) => void;
  close: () => void;
};
const ModalDelete = forwardRef<ModalDeleteRef>(({ }, ref) => {
  const sm = useSMLayout();
  const [open, setOpen] = useState(false);
  const configRef = useRef<{ title: string, message: string }>({ message: '', title: '' });
  const callbackRef = useRef<() => void>(() => undefined);

  const handleOk = () => {
    setOpen(false);
    callbackRef.current();
  };

  useImperativeHandle(ref, () => ({
    open(configs, callback) {
      configRef.current.message = configs.message;
      configRef.current.title = configs.title;
      callbackRef.current = callback;
      setOpen(true);
    },
    close: () => setOpen(false),
  }));

  return (
    <Modal
      open={open}
      forceRender
      footer
      centered
      closable={false}
      onCancel={() => setOpen(false)}
      width={sm ? '327px' : '551px'}
    >
      <Styled sm={sm}>
        <p className="title-modal">{configRef.current.title}</p>
        <p className="message-modal">
          {configRef.current.message}
        </p>
        <div className="actions">
          <Button danger onClick={handleOk} block={sm} className='action-item' type='primary' >Delete</Button>
          <Button onClick={() => setOpen(false)} block={sm} className='action-item' grey >Cancel</Button>
        </div>
      </Styled>
    </Modal>
  );
});
export const useModalDelete = (): [React.RefObject<ModalDeleteRef>, JSX.Element] => {
  const ref = useRef<ModalDeleteRef>(null);
  const component = <ModalDelete ref={ref} />;
  return [ref, component];
};
ModalDelete.displayName = 'ModalDelete';
export default ModalDelete;

const Styled = styled.div<{ sm?: boolean }>`
  .title-modal {
    color: var(--text1, #0B3558);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }
  .message-modal {
    color: #476788;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 32px;
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 24px;
    .action-item {
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      height: 44px;
    }
  }

  ${({ sm }) => sm ? `

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
    .action-item {
      flex: 1 0 0;
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      height: 44px;
      padding: 4px 8px;
      span {
        font-size: 14px;
      }
    }
  }
    
  `: ''}
`;

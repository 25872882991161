import styled from 'styled-components';
import FormLogin from './components/FormLogin';
import Footer from './components/Footer';
import Header from 'features/auth/components/Header';

const UIWebLoginPage = () => {
  return (
    <Styled>
      <Header />
      <div style={{ marginBottom: '32px' }} />
      <div className="ContainerWeb large box-form">
        <FormLogin />
        <div style={{ marginBottom: '16px' }} />
        <Footer />
      </div>
    </Styled>
  );
};

export default UIWebLoginPage;
const Styled = styled.div`
background:#fff;
min-height: 100vh;
.box-form {
  border-radius: 5px;
  background: #F2F3F8;
  display: flex;
  max-width: 612px;
  padding: 24px 40px;
  flex-direction: column;
  align-items: center;
}
`;

import { Form, Modal } from 'antd';
import styled from 'styled-components';
import BoxChooseImage from './BoxChooseImage';
import FormInfo from './FormInfo';
import MessageErrorSizeImage from './MessageErrorSizeImage';
import useNewReviewHook from './useNewReviewHook';
type Props = {
  buttonRender: (onOpen: () => void) => any;
  mine?: boolean;
};
const ButtonAddBlog = ({ buttonRender, mine }: Props) => {
  const {
    onClose, onOpen, visible,
    form, handleFinish, msgErrorImageRef,
    onSaveDraft, onSave,
  } = useNewReviewHook(mine);

  return (
    <>
      {buttonRender(onOpen)}
      <Modal
        open={visible}
        onCancel={onClose}
        forceRender
        footer={null}
        closable={false}
        centered
        width={'60vw'}
        wrapClassName='modal-non-style modal-max-width-80vw'
      >
        <ModalAddContainer>
          <div className="modal-title">NEW BANLIST CUSTOMER</div>
          <Form form={form} layout='vertical' className="modal-children" onFinish={handleFinish}>
            <div className="col-children-left">
              <div className="section-title">Images</div>
              <div className="section-subtitle">Maximum 6 images</div>
              <Form.Item name='images' noStyle>
                <BoxChooseImage msgErrorImageRef={msgErrorImageRef} />
              </Form.Item>
            </div>
            <div className="col-children-right">
              <FormInfo />
            </div>
          </Form>
          <div className="modal-footer">
            <button className="btn btn-cancel" onClick={onClose}><span>Cancel</span></button>
            <button className="btn btn-save-draft" onClick={onSaveDraft} ><span>Save as draft</span></button>
            <button className="btn btn-publish" onClick={onSave}><span>Publish</span></button>
          </div>
          <MessageErrorSizeImage ref={msgErrorImageRef} />
        </ModalAddContainer>
      </Modal>
    </>
  );
};

export default ButtonAddBlog;

const ModalAddContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 5px;
  background: #F2F3F8;
  position: relative;
  .modal-title {
    color: #0B3558;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }
  .modal-children {
    min-height: auto;
    display: flex;
    width: 100%;
    background: #FCFCFC;
    .col-children-left {
      border: 1px solid #D6D6D6;
      padding: 24px;
      .section-title {
        color: #0B3558;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .section-subtitle {
        color: #476788;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .col-children-right {
      border: 1px solid #D6D6D6;
      padding: 24px;
      flex:1;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    button.btn {
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 5px;
      outline: none;
      border: none;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      &:disabled {
        opacity: 0.5;
      }
      span {
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }

      &.btn-cancel {
        border: 1px solid #D6D6D6;
        background: #FFF;
        width: 138.333px;
        span {
          color: #808080;
        }
      }

      &.btn-save-draft {
        background: #006BFF;
        span {
          color: #fff;
        }
      }

      &.btn-publish {
        background: #00B466;
        span {
          color: #fff;
        }
      }
    }
  }
`;

import ViewImagesModal from 'components/ViewImagesModal';
import RootScreens from 'features/RootScreens';
import ModalGuardLogin from 'features/home/components/ModalGuardLogin';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import LoadingPage from 'services/UI/LoadingPage';
import store from 'store';
import { App as AppContext } from 'antd';


const AppLayout: React.FC = () => <RootScreens />;

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AppContext>
        <AppLayout />
      </AppContext>
      <LoadingPage />
      <ModalGuardLogin />
      <ViewImagesModal />
    </Provider>
  );
};

export default App;

import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import homeActions from './home/services/actions';
import mineActions from 'features/mine/services/actions';
import ModalReportBlog from 'widgets/ModalReportBlog';

const SideEffectInit = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(homeActions.init());
    dispatch(mineActions.init());
  }, []);

  return (
    <>
      <Outlet />
      <ModalReportBlog />
    </>
  );
};

export default SideEffectInit;

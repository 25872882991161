import { Skeleton } from 'antd';
import styled from 'styled-components';
import { maskPhone } from 'utils/formatPhone';
type Props = {
  sm?: boolean;
  name?: string;
  phone?: string;
};
const CustomerInfo = ({ sm, name, phone }: Props) => {
  const customerName = name || 'Unknown';
  const customerPhone = phone ? maskPhone(phone) : '--';
  if (sm) return (
    <SMStyled>
      <div className="customer-name">{customerName}</div>
      <div className="customer-phone-group">
        <div className="customer-phone">{customerPhone}</div>
      </div>
    </SMStyled>
  );

  return (
    <LGStyled>
      <div className="customer-name">{customerName}</div>
      <div className="customer-phone">{customerPhone}</div>
    </LGStyled>
  );
};
export const CustomerInfoSkeleton = ({ sm }: Props) => {
  if (sm) return (
    <SMStyled>
      <Skeleton.Input className="customer-name" active />
      <div className="customer-phone-group" style={{ marginTop: 4 }}>
        <Skeleton.Input className="customer-name" size='small' active />
      </div>
    </SMStyled>
  );

  return (
    <LGStyled>
      <Skeleton.Input className="customer-name" size='small' active />
      <Skeleton.Input className="customer-name" size='small' active />
    </LGStyled>
  );
};

export default CustomerInfo;
const Styled = styled.div`
  .customer-name {
    color: #0B3558;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .customer-phone {
    color: #476788;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const SMStyled = styled(Styled)`
  display: flex;
  align-items: center;
  gap: 8px;
  .customer-phone-group {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;
const LGStyled = styled(Styled)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

import { Collapse, Form, Input, InputRef, Modal, notification } from 'antd';
import Button from 'components/Button';
import dayjs from 'dayjs';
import homeActions from 'features/home/services/actions';
import homeApis from 'features/home/services/apis';
import { BlogAction } from 'features/home/services/constants';
import homeSelectors from 'features/home/services/selectors';
import { IBlogItem } from 'features/home/services/types/blog';
import { get } from 'lodash';
import { useRef, useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';

const reasonList = [
  { label: 'False content', value: '1' },
  { label: 'Promotional and violent content', value: '2' },
  { label: 'Legal issue', value: '3' },
  { label: 'Fraudulent and misleading content', value: '4' },
  { label: 'Other reasons', value: 'OTHER' },
];

const ModalReportBlog = () => {
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const sm = useSMLayout();
  const dispatch = useAppDispatch();
  const draftDataReport = homeSelectors.draftDataReport();
  const [typeReport, setTypeReport] = useState<string>('');
  const setLoading = useSetLoadingPage();

  const handleClose = () => {
    dispatch(homeActions.setDraftDataReport(null));
    form.resetFields();
    setTypeReport('');
  };

  const onReport = () => form.submit();

  const handleChangeType = (o: { label: string, value: string }) => {
    setTypeReport(o.value);
    form.setFieldValue('text', o.label);
    form.validateFields();
    if (o.value === 'OTHER') {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  const handleFinish = async (values: { text: string, other: string }) => {
    if (!draftDataReport) return;
    let reportNote = values.text;
    if (typeReport === 'OTHER') {
      reportNote = values.other;
    }
    handleClose();
    setLoading(true);
    try {
      const res: IResponseDataBody<IBlogItem> = await homeApis.updateActionBlog({
        action: BlogAction.REPORT,
        dateTime: dayjs().format('MM-DD-YYYY HH:mm:ss'),
        blogId: draftDataReport.id,
        reportNote,
      });
      if (res.data.data) {
        dispatch(homeActions.updateBlogItem(res.data.data));
        notification.success({
          message: 'Report Successfully',
          duration: 3,
        });
      }
    } catch (error) {
      const msg = get(error, 'response.data.message', '');
      notification.error({
        message: msg || 'fail!',
        duration: 3,
      });
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={!!draftDataReport}
      forceRender
      footer
      centered
      closable={false}
      onCancel={handleClose}
      width={sm ? '90vw' : '551px'}
      wrapClassName='modal-non-style'
    >
      <Styled className={sm ? 'small' : ''}>
        <p className="title-modal">Report</p>
        <div className="highlight-text">
          <span>Select a reason to report this post</span>
        </div>
        <Form form={form} className='form-report' onFinish={handleFinish}>
          <div className="reasons">
            {reasonList.map(o => (
              <Button htmlType='button' block type='text' onClick={() => handleChangeType(o)} ghost key={o.value} className={`reason-item ${o.value}`}>
                <span>{o.label}</span>
                {typeReport !== o.value ?
                  <Button htmlType='button' onClick={() => handleChangeType(o)}><div className="blank-checkbox"></div></Button> :
                  <Button htmlType='button' onClick={() => handleChangeType(o)}>
                    <div className="blank-checkbox selected-checkbox">
                      <IconTick />
                    </div>
                  </Button>}
              </Button>
            ))}
            <WrapCollapse>
              <Collapse
                collapsible="icon"
                expandIconPosition={'end'}
                activeKey={typeReport === 'OTHER' ? '1' : undefined}
                bordered={false}
                items={[{
                  key: '1',
                  label: null,
                  children: <OtherStyled className={sm ? 'small' : ''}>
                    <div className="label-input-other">Enter a description to continue</div>
                    {typeReport === 'OTHER' ?
                      <Form.Item name={'other'} rules={[{ required: true, message: 'Please enter or choose reason' }]}>
                        <Input.TextArea ref={inputRef} rows={sm ? 3 : 5} />
                      </Form.Item>
                      : null}
                  </OtherStyled>
                }]}
              />
            </WrapCollapse>
            <Form.Item className='form-validate-item' name={'text'} rules={[{ required: true, message: 'Please enter or choose reason' }]} ></Form.Item>
          </div>
        </Form>

        <div className="actions">
          <Button onClick={onReport} block={sm} className='action-item' type='primary' >Report</Button>
          <Button onClick={handleClose} block={sm} className='action-item' grey >Cancel</Button>
        </div>
      </Styled>
    </Modal>
  );
};

export default ModalReportBlog;

const OtherStyled = styled.div`
  padding: 0 16px;
  &.small {
    padding: 0px; 
  }
  .label-input-other {
    color: #476788;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
  .ant-input {
    color: #0B3558;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    &::placeholder {
      color: #808080;
      opacity: 1; /* Firefox */
    }
    
    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: #808080;
    }
  }
`;
const WrapCollapse = styled.div`
  .ant-collapse-item .ant-collapse-header {
    display: none;
  }
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0;
      background: #fff;
    }
  }
`;


const Styled = styled.div<{ sm?: boolean }>`
  padding: 16px;
  .title-modal {
    color: #0B3558;
    text-align: left;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
  }
  .highlight-text {
    display: flex;
    padding: 16px 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: #E7EDF6;
    margin-bottom: 24px;
    span {
      color: #5F5F5F;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .form-report {
    width: 100%;
    .reasons {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 32px;
    }
    .ant-form-item.form-validate-item {
      .ant-form-item-control-input {
        margin: 0;
        min-height: unset;
        padding: 0 16px;
      }
      .ant-form-item-control {
        padding: 0 16px;
      }
    }
    .reason-item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 16px 16x;
      height: 46px;
      border-bottom: 1px solid #E7EDF6;
      &.OTHER {
        border-bottom: unset;
      }
      span {
        flex: 1;
        text-align: left;
        color: #0B3558;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
      .ant-btn {
        padding: 0;
        border-radius: 100px;
      }
      .blank-checkbox {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
      }
      .selected-checkbox {
        background: #006BFF;
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 24px;
    .action-item {
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      height: 44px;
    }
  }

  &.small {
    .form-report {
      .reasons {
        margin-bottom: 0;
      }
      .reason-item {
        span {
          font-size: 14px;
        }
        
        &.ant-btn {
          padding: 0 !important;
        }
      }
    }
    .actions {
      display: flex;
      align-items: center;
      gap: 16px;
      .action-item {
        flex: 1 0 0;
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        height: 44px;
        padding: 4px 8px;
        span {
          font-size: 14px;
        }
      }
    }
      
  }
`;

const IconTick = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7974 6.64121C16.5903 6.4348 16.2511 6.4493 16.0625 6.67262L10.4257 13.3442L8.21671 11.0374C8.02273 10.8349 7.70012 10.8315 7.50188 11.0299L6.37017 12.1625C6.17803 12.3548 6.17473 12.6654 6.36275 12.8617L10.0812 16.7448C10.2199 16.8896 10.4243 16.9326 10.602 16.8729C10.745 16.874 10.8878 16.8143 10.9881 16.6956L11.7297 15.8179L12.2734 15.2737L12.2288 15.2271L17.958 8.44622C18.1259 8.24746 18.1134 7.95313 17.9291 7.76943L16.7974 6.64121Z" fill="white" />
  </svg>
);

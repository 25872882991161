import { App, Form, Input } from 'antd';
import PasswordPrefix from 'assets/Icons/SignUp/PasswordPrefix';
import UserNamePrefix from 'assets/Icons/SignUp/UserNamePrefix';
import Button from 'components/Button';
import authApis from 'features/auth/services/apis';
import { IBodyAPILoginData, IBodyAPISignUpData } from 'features/auth/services/types/api';
import { IAuthLoginResData } from 'features/auth/services/types/auth';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import mineActions from 'features/mine/services/actions';
import { HEADER_API } from 'services/request';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import { useSMLayout } from 'utils/withLayout';
interface IFormData extends IBodyAPISignUpData {
  confirmPassword: string;
}

const FormLogin = () => {
  const { notification } = App.useApp();
  const dispatch = useAppDispatch();
  const sm = useSMLayout();
  const [form] = Form.useForm();
  const setLoading = useSetLoadingPage();
  const navigate = useNavigate();

  const handleFinish = async (values: IFormData) => {
    setLoading(true);

    const body: IBodyAPILoginData = {
      password: values.password,
      userName: values.userName || values.email,
    };

    try {
      const res: IResponseDataBody<IAuthLoginResData> = await authApis.login(body);
      const data = res.data.data;
      if (data) {
        dispatch(mineActions.setAuthUserInfo(data.user));
        dispatch(mineActions.getSearchCustomers.fetch());
        storage.token.set(data.accessToken);
        HEADER_API.token = data.accessToken;
        storage.refreshToken.set(data.refreshToken);
        storage.username.set(data.user.email);
        if (data.user.name)
          notification.success({
            message: `Hi, ${data.user.name}`,
            duration: 3,
          });

        navigate('/');
      }
    } catch (error: any) {
      const msg = get(error, 'response.data.message', '');
      notification.error({
        message: msg || 'Login fail!',
        duration: 3
      });
    }
    finally {
      setLoading(false);
    }
  };

  const getDefaultEmail = () => {
    const username = storage.username.get();
    if (!username) return '';
    if (username === 'undefined') return '';
    return username;
  };

  const FormInfo = () => {
    return (
      <Form form={form} onFinish={handleFinish} className="form-container" initialValues={{ email: getDefaultEmail() }}>
        <Form.Item name={'email'} rules={[{ required: true, message: 'Please enter email' }]}>
          <Input prefix={<UserNamePrefix />} placeholder={'Username or email'} />
        </Form.Item>
        <Form.Item name={'password'} rules={[{ required: true, message: 'Please enter password' }]}>
          <Input.Password prefix={<PasswordPrefix />} placeholder="Password"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                form.submit();
              }
            }}
          />
        </Form.Item>
      </Form>
    );
  };

  if (sm) return (
    <AppStyled>
      <div className="sign-up-title" style={{ marginBottom: '24px' }}>LOG IN</div>
      <FormInfo />
      <Button
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        size='large' onClick={() => form.submit()} block type='primary' style={{ height: '46px', marginTop: '24px' }}>Log In</Button>
    </AppStyled>
  );
  return (
    <WebStyled>
      <div className="sign-up-title" style={{ marginBottom: '24px' }}>LOG IN</div>
      <FormInfo />
      <Button
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        size='large' onClick={() => form.submit()} block type='primary' style={{ height: '46px' }}>Log In</Button>
    </WebStyled>
  );
};

export default FormLogin;

const Styled = styled.div`
  .sign-up-title {
    color: #0B3558;
    text-align: center;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 114.286% */
  }
  .confirm-pw-block {
    .warning-text {
      color: #DF0D0D;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 200% */
    }
    .password-rules {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .rule-item {
        display: flex;
        align-items: center;
        gap: 4px;
        span {
          color: #808080;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 200% */
        }
      }
    }
  }
  .ant-input-affix-wrapper {
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E7EDF6;
    background: #FFF;
    .ant-input {
      color: #0B3558;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &::placeholder {
        color: #808080;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 1; /* Firefox */
      }
      
      &::-ms-input-placeholder { /* Edge 12 -18 */
        color: #808080;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;
const WebStyled = styled(Styled)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  .form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .form-container-item {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
`;
const AppStyled = styled(Styled)`
  .ant-form-item {
    margin-bottom: 20px;
  }
`;

import { Skeleton, Tag } from 'antd';
import Button from 'components/Button';
import styled from 'styled-components';
import getCreatedDateLabel from 'utils/createdDate';
type Props = {
  address?: string;
  createdDate?: string;
  businessName?: string;
  mine?: boolean;
  onDelete?: () => void;
  draft?: boolean;
  small?: boolean;
};
const InfoUser = ({ createdDate, small, mine, onDelete, draft }: Props) => {
  if (mine) return (
    <Styled className={small ? 'small' : ''} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <p className="publish-date">{getCreatedDateLabel(createdDate) || '--'}{draft ? <Tag style={{ marginLeft: '8px' }} color="blue">Draft</Tag> : null}</p>
      <div style={{ flex: 1 }} />
      <Button onClick={onDelete} danger ghost type='primary' ><IconTrash />Delete</Button>
    </Styled>
  );
  return (
    <Styled className={small ? 'small' : ''}>
      <p className="publish-date">{getCreatedDateLabel(createdDate) || '--'}</p>
    </Styled>
  );
};

export const InfoUserSkeleton = () => {
  return (
    <StyledSkeleton>
      <Skeleton.Input active block size='large' style={{ height: 15, width: '100%' }} />
      <Skeleton.Input active style={{ height: 15, width: 150 }} />
    </StyledSkeleton>
  );
};

export default InfoUser;
const StyledSkeleton = styled.div`
  display: flex;
  flex-direction: column;
`;
const Styled = styled.div`
  .publish-date {
    color: #5F5F5F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .spa-name {
    color: #5F5F5F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .address {
    color: #5F5F5F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &.small {
    .publish-date {
      color: #5F5F5F;
      font-size: 12px;
      opacity: 0.5;
    }
  }
`;

const IconTrash = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.7119 5.30928L8.71136 5.30043C8.68293 4.83558 8.63819 4.1038 9.25539 4.01923C10.0808 3.99637 10.9068 3.99991 11.7326 4.00346C12.3633 4.00616 12.9939 4.00887 13.6243 3.99982C13.666 4.00132 13.7092 4.00177 13.7533 4.00224C14.0154 4.00497 14.3064 4.00802 14.4738 4.23424C14.6545 4.45141 14.6431 4.73205 14.632 5.00483V5.00484C14.6278 5.1109 14.6235 5.21577 14.6306 5.31525C15.1841 5.33382 15.7382 5.32984 16.2922 5.32587C16.8965 5.32154 17.5007 5.3172 18.1036 5.34213C18.6287 5.48906 18.6147 6.06243 18.6035 6.51667V6.51671C18.6024 6.56098 18.6013 6.60412 18.6008 6.64562C13.9811 6.64413 9.36141 6.64413 4.7417 6.64562C4.74091 6.61034 4.73979 6.57384 4.73863 6.53644C4.72456 6.07987 4.70633 5.48853 5.2404 5.34362C5.85628 5.311 6.473 5.31611 7.08989 5.32123H7.08989H7.0899C7.63059 5.32572 8.17141 5.3302 8.7119 5.30928ZM9.43669 4.44743H14.028V5.3433H9.43669V4.44743ZM6.07344 7.47814C7.05187 7.47775 8.03029 7.47623 9.00872 7.47472C11.7661 7.47044 14.5234 7.46616 17.2808 7.4871C17.0451 9.60487 16.8241 11.7245 16.6031 13.8442L16.6031 13.8443C16.4321 15.4847 16.2611 17.1251 16.0833 18.7646C16.043 19.4126 15.4711 19.9949 14.8126 19.9949C13.5541 19.9986 12.2956 19.9978 11.0371 19.9969H11.0363C10.2495 19.9964 9.46274 19.9959 8.67594 19.9964C7.97716 20.0502 7.29481 19.4947 7.26196 18.7825L7.12645 17.5024C6.77278 14.1617 6.419 10.8199 6.07344 7.47814Z" fill="#DF0D0D" />
  </svg>
);

import authActions from 'features/auth/services/actions';
import authSelectors from 'features/auth/services/selectors';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';

const useGoNewReview = () => {
  const dispatch = useAppDispatch();
  const isAuth = authSelectors.isAuth();
  const navigate = useNavigate();
  return () => {
    if (isAuth) return navigate('/new-review');
    dispatch(authActions.enableGuardLogin(true));
  };
};

export default useGoNewReview;

import { Button, Form, Input, notification } from 'antd';
import DotIcon from 'assets/Icons/SignUp/DotIcon';
import PasswordPrefix from 'assets/Icons/SignUp/PasswordPrefix';
import ValidRuleIcon from 'assets/Icons/ValidRuleIcon';
import { IBodyApiUpdatePassword } from 'features/home/services/types/api';
import mineApis from 'features/mine/services/apis';
import { get } from 'lodash';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';

const PasswordTab = () => {
  const sm = useSMLayout();
  const [form] = Form.useForm();
  const setLoading = useSetLoadingPage();
  const handleFinish = async (values: any) => {
    setLoading(true);
    const body: IBodyApiUpdatePassword = {
      passwordNew: values.passwordConfirm,
      passwordOld: values.passwordOld,
    };
    try {
      const res: IResponseDataBody<boolean> = await mineApis.updatePassword(body);
      if (res.data.data) {
        notification.success({
          message: 'Updated Successfully',
          duration: 3,
        });
        form.resetFields();
      }
    } catch (error) {
      const msg = get(error, 'response.data.message', '');
      notification.error({
        message: msg || 'fail!',
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };
  const getValid = (values: { passwordOld: string, passwordNew: string, passwordConfirm: string }) => {
    const oldPW = values.passwordOld?.trim() || '';
    const newPW = values.passwordNew?.trim() || '';
    const confirmPW = values.passwordConfirm?.trim() || '';
    if (oldPW === newPW) return false;
    if (newPW !== confirmPW) return false;
    const rules = [
      confirmPW?.length >= 8,
      /(?=.*\d)/.test(confirmPW),
      confirmPW ? /(?=.*[a-z])/.test(confirmPW) : false,
      /[-+_!@#$ %^&*.,?]/.test(confirmPW),
      /(?=.*[A-Z])/.test(confirmPW),
    ];
    return rules.every(o => !!o);
  };
  return (
    <Styled className={sm ? 'small' : ''} size='large' layout='vertical' form={form} onFinish={handleFinish}>
      <Form.Item label="Old password" name={'passwordOld'}>
        <Input.Password prefix={<PasswordPrefix />} placeholder="Old password" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const password: string = getFieldValue('passwordOld');
          return (
            <Form.Item label="New Password" name={'passwordNew'}
              rules={[
                {
                  validator(rule, value) {
                    if (!value) {
                      return Promise.reject('Please enter new password!');
                    }
                    if (value.trim() === password?.trim())
                      return Promise.reject('Your new password must not match your current password');

                    return Promise.resolve();
                  },
                }
              ]}
            >
              <Input.Password prefix={<PasswordPrefix />} placeholder="New password" />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item shouldUpdate className="confirm-form-item">
        {({ getFieldValue }) => {
          const password = getFieldValue('passwordNew');
          return (
            <Form.Item label="Confirm Password" name={'passwordConfirm'}
              rules={[
                {
                  validator(rule, value) {
                    if (!value) {
                      return Promise.reject('Please enter confirm password!');
                    }
                    if (value !== password)
                      return Promise.reject('Confirm password not match!');

                    return Promise.resolve();
                  },
                }
              ]}
            >
              <Input.Password prefix={<PasswordPrefix />} placeholder="Confirm password" />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const password = getFieldValue('passwordNew');
          const rules = [
            { label: '8 characters minimum', active: password?.length >= 8 },
            { label: '1 number', active: /(?=.*\d)/.test(password) },
            { label: '1 lowercase letter', active: password ? /(?=.*[a-z])/.test(password) : false },
            { label: '1 special character', active: /[-+_!@#$%^&*.,?]/.test(password) },
            { label: '1 uppercase letter', active: /(?=.*[A-Z])/.test(password) },
          ];
          return (<PasswordRuleForm rules={rules} />);
        }}
      </Form.Item>
      <Form.Item shouldUpdate className="submit-form-item">
        {({ getFieldsValue, }) => {
          const values: { passwordOld: string, passwordNew: string, passwordConfirm: string } = getFieldsValue();
          const isValid = getValid(values);

          return (
            <div className='btn-submit-wrap'>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isValid}
                block={sm}
              >
                Save Changes
              </Button>
            </div>
          );
        }}
      </Form.Item>
    </Styled>
  );
};

const PasswordRuleForm = ({ rules }: {
  rules: {
    label: string;
    active: boolean;
  }[]

}) => {
  return (
    <>
      <p className='warning-text'>Please enter a password that meets all of the requirements below.</p>
      <div className='password-rules'>
        {rules.map((o, i) => (
          <div key={i} className="rule-item">
            {!o.active ? <DotIcon /> : <ValidRuleIcon />}
            <span style={{ color: !o.active ? '#808080' : '#00B466' }}>{o.label}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default PasswordTab;
const Styled = styled(Form)`
  display: flex;
  width: 100%;
  padding: 16px 24px;
  flex-direction: column;
  flex-shrink: 0;
  background: #FFF;
  .ant-form-item {
    margin-bottom: 12px;
    .ant-form-item-label {
      padding: 0;
      label {
        height: unset;
        margin-bottom: 8px;
      }
    }
  }
  .ant-form-item.submit-form-item {
    margin-bottom: 0;
  }
  
  .btn-submit-wrap {
    display: flex;
    width: 100%;
    justify-content: end;
  }

  .password-rules {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .rule-item {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        color: #808080;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 200% */
      }
    }
  }

  &.small {
    padding-bottom: 50px;
    .ant-form-item.submit-form-item {
      padding: 16px 16px;
      position: fixed;
      background: #fff;
      bottom: 0;
      z-index: 99;
      left: 0;
      right: 0;
      border-top: 1px solid #E5E5E5;
    }
  }
`;
import { ButtonProps, Button as ButtonUIKit, ConfigProvider } from 'antd';
interface IButtonProps extends ButtonProps {
  success?: boolean;
  grey?: boolean;
  danger?: boolean;
}
const Button = ({ success, grey, danger, ...rest }: IButtonProps) => {
  if (success) return (<ConfigProvider
    theme={{
      components: {
        Button: {
          colorPrimary: '#00B466',
          colorPrimaryHover: '#00b466bf',
          colorPrimaryActive: '#00B466',
          lineWidth: 0,
        },
      },
    }}
  >
    <ButtonUIKit type='primary'  {...rest} />
  </ConfigProvider>
  );

  if (danger) return (<ConfigProvider
    theme={{
      components: {
        Button: {
          colorPrimary: '#DF0D0D',
          colorPrimaryHover: '#DF0D0Dbf',
          colorPrimaryActive: '#DF0D0D',
          lineWidth: 0,
        },
      },
    }}
  >
    <ButtonUIKit type='primary' {...rest} className={'ant-btn-danger ' + rest.className} />
  </ConfigProvider>
  );

  if (grey) return (<ConfigProvider
    theme={{
      components: {
        Button: {
          colorPrimary: '#D9D9D9',
          colorPrimaryHover: '#D9D9D9bf',
          colorPrimaryActive: '#D9D9D9',
          lineWidth: 0,
        },
      },
    }}
  >
    <ButtonUIKit type='primary' {...rest} className={'ant-btn-grey ' + rest.className} />
  </ConfigProvider>
  );

  return (
    <ButtonUIKit {...rest} />
  );
};

export default Button;

import { createAction } from '@reduxjs/toolkit';
import { IAuthUserInfo } from 'features/auth/services/types/auth';
import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiGetBlogParams } from 'features/home/services/types/api';

const setAuthUserInfo = createAction<IAuthUserInfo>(PREFIX_ACTIONS + 'setAuthInfo');

const getHistoryActions = createAsyncAction(PREFIX_ACTIONS + 'getHistoryActions');
const getSearchCustomers = createAsyncAction(PREFIX_ACTIONS + 'getSearchCustomers');

const init = createAction(PREFIX_ACTIONS + 'getInitData');

const getDetailInfo = createAsyncAction(PREFIX_ACTIONS + 'getDetailInfo');
const initSetting = createAction(PREFIX_ACTIONS + 'initSetting');

const getBlogs = createAsyncAction<IApiGetBlogParams>(PREFIX_ACTIONS + 'getBlogs');
const setParams = createAction<IApiGetBlogParams>(PREFIX_ACTIONS + 'setParams');

const getDraftBlogs = createAsyncAction<IApiGetBlogParams>(PREFIX_ACTIONS + 'getDraftBlogs');
const setDraftParams = createAction<IApiGetBlogParams>(PREFIX_ACTIONS + 'setDraftParams');

const mineActions = {
  setAuthUserInfo,
  getHistoryActions,
  getSearchCustomers,
  init,
  getDetailInfo,
  initSetting,
  getBlogs,
  setParams,
  getDraftBlogs,
  setDraftParams,
};

export default mineActions;


// import fetch from 'services/request';

import fetch from 'services/request';
import { IBodyAPILoginData, IBodyAPISignUpData, IBodyAPIVerifyUser } from './types/api';

// const baseURL = 'api/v1/:merchant_code/demoData';

export const getDemoData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ data: { data: [] } });
    }, 1000);
  });
  // return fetch({
  //   method: 'get',
  //   url: `${baseURL}/getDemoData`,
  //   params: params as any
  // });
};

const signUp = (body: IBodyAPISignUpData) => {
  return fetch({
    method: 'post',
    url: '/api/v1/ban-list/auth/sign-up',
    body,
  });
};

const login = (body: IBodyAPILoginData) => {
  return fetch({
    method: 'post',
    url: '/api/v1/ban-list/auth/login',
    body
  });
};

const verifyUser = (body: IBodyAPIVerifyUser) => {
  return fetch({
    method: 'post',
    url: '/api/v1/ban-list/auth/verify-user',
    body
  });
};

const resendOTP = (userId: string) => {
  return fetch({
    method: 'post',
    url: `/api/v1/ban-list/auth/resend-otp/${userId}`,
  });
};

const authApis = {
  getDemoData,
  signUp,
  login,
  verifyUser,
  resendOTP,
};

export default authApis;

import { Skeleton } from 'antd';
import { viewImagesModalCarouselRef } from 'components/ViewImagesModal';
import React from 'react';
import uiActions from 'services/UI/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
type Props = {
  images?: string[];
};
const SMImages = ({ images = [] }: Props) => {
  const sm = useSMLayout();
  const dispatch = useAppDispatch();
  const handleOpenImage = (index: string) => () => {
    dispatch(uiActions.setImageView(images));
    setTimeout(() => {
      viewImagesModalCarouselRef.current?.goTo(images?.indexOf(index), true);
    }, 100);
  };

  const length = images?.length;
  if (length == 0) return null;

  switch (length) {
    case 1: {
      const item = images[0];
      return (
        <Styled
          className={sm ? 'small' : ''}
          onClick={handleOpenImage(item)}
          style={{ gridTemplateColumns: '1fr' }}>
          <div className="detail-card-info-image-item" style={{ width: '100%', height: sm ? '100%' : '300px' }}>
            <img src={item} />
          </div>
        </Styled>
      );
    }
    case 2: {
      const item = images[0];
      const item2 = images[1];
      return (
        <Styled
          className={sm ? 'small' : ''}
          onClick={handleOpenImage(item)}
          style={{ gridTemplateColumns: '1fr 1fr', width: '100%', height: sm ? '50vw' : 'auto', minHeight: 'unset' }}>
          <div
            className="detail-card-info-image-item" style={{ width: '100%', height: sm ? '100%' : '200px' }}>
            <img src={item} />
          </div>
          <div
            onClick={handleOpenImage(item2)}
            className="detail-card-info-image-item" style={{ width: '100%', height: sm ? '100%' : '200px' }}>
            <img src={item2} />
          </div>
        </Styled>
      );
    }
    case 3: {
      const item = images[0];
      const item2 = images[1];
      const item3 = images[2];
      return (
        <Styled
          className={sm ? 'small' : ''}
          onClick={handleOpenImage(item)}
          style={{ gridTemplateColumns: '1fr 1fr', height: 'auto' }}>
          <div
            className="detail-card-info-image-item single-image" style={{ gridColumn: '1 / span 2', width: '100%', height: sm ? '40vw' : '250px' }}>
            <img src={item} />
          </div>
          <div
            onClick={handleOpenImage(item2)}
            className="detail-card-info-image-item" style={{ width: '100%', height: sm ? '40vw' : '200px' }}>
            <img src={item2} />
          </div>
          <div
            onClick={handleOpenImage(item3)}
            className="detail-card-info-image-item" style={{ width: '100%', height: sm ? '40vw' : '200px' }}>
            <img src={item3} />
          </div>
        </Styled>
      );
    }
    default:
      return (
        <Styled
          className={sm ? 'small' : ''}
          style={{ width: '100%', height: '80vw' }}
        >
          {images?.slice(0, 4)?.map((o, i) => {
            return (
              <div
                key={i} className="detail-card-info-image-item"
                onClick={handleOpenImage(o)}
                style={{ width: '100%', height: '100%' }}
              >
                <img src={o} />
                {(i === 3 && length - 4 > 0) ? <div className='detail-card-info-image-overlay'><span>{length - 4}+</span></div> : null}
              </div>
            );
          })}
        </Styled>
      );
  }
};

export default SMImages;

export const ImageSkeleton = () => {
  return (<Styled>
    <Skeleton.Image active style={{ height: '132px', width: '132px' }} />
  </Styled>);
};

const Styled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  height: 20vw;
  width: 100%;
  .single-image {
  }
  .detail-card-info-image-item {
    width: 64px;
    height: 64px;
    display: flex;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
    img {
      width: 100%;
      height: 100%;
      flex-shrink: 0;
    }
    .detail-card-info-image-overlay {
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.60);
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  &.small {
    height: 80vw;
    .single-image {
      width: 100%;
    }
  }
`;
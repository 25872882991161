import BlogSM, { BlogSMSkeleton } from 'components/Blog/BlogSM';
import EmptyReview from 'components/EmptyReview';
import styled from 'styled-components';
import homeSelectors from '../services/selectors';
import mineSelectors from 'features/mine/services/selectors';
import authSelectors from 'features/auth/services/selectors';

const MostInteraction = () => {
  const data = homeSelectors.getMostInteraction();
  const loading = homeSelectors.getMostInteractionLoading();
  const historyActions = mineSelectors.historyActions();
  const authInfo = mineSelectors.authInfo();
  const isAuth = authSelectors.isAuth();

  if (loading) return <LoadingUI />;
  if (!data.length) return <EmptyUI />;

  return (
    <WebStyled>
      <div className="title-div">
        <div className="title-section">MOST INTERACTION</div>
      </div>
      <MostInteractionList>
        {data.map(o => (
          <BlogSM
            key={o.id}
            data={o}
            historyActions={historyActions}
            authInfo={authInfo}
            isAuth={isAuth}
          />
        ))}
      </MostInteractionList>
    </WebStyled>
  );
};

export default MostInteraction;

const LoadingUI = () => (
  <WebStyled>
    <div className="title-div">
      <div className="title-section">MOST INTERACTION</div>
    </div>
    <MostInteractionList>
      <BlogSMSkeleton />
      <BlogSMSkeleton />
      <BlogSMSkeleton />
    </MostInteractionList>
  </WebStyled>
);

const EmptyUI = () => (
  <WebStyled>
    <div className="title-div">
      <div className="title-section">MOST INTERACTION</div>
    </div>
    <MostInteractionList>
      <div className="empty-box">
        <EmptyReview />
      </div>
    </MostInteractionList>
  </WebStyled>
);

const MostInteractionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  .empty-box {
    width: 100%;
    height: 268px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Styled = styled.div`
  .title-div {
    height: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 4px;
  }
  .title-section {
    color: #476788;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.8px; /* 144% */
    text-transform: uppercase;
  }
`;
const WebStyled = styled(Styled)``;

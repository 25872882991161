import EmptyReviewIcon from 'assets/Icons/EmptyReviewIcon';
import React from 'react';
import styled from 'styled-components';

const EmptyReview = () => {
  return (
    <Styled>
      <EmptyReviewIcon />
      <p className="empty-title">Empty Review</p>
    </Styled>
  );
};

export default EmptyReview;
const Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  .empty-title {
    color: #808080;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
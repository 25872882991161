import { Navigate, Outlet } from 'react-router-dom';
import authSelectors from './auth/services/selectors';

const GuardLogin = () => {
  const isAuth = authSelectors.isAuth();
  if (!isAuth) return <Navigate to={'/'} />;
  return <Outlet />;
};

export default GuardLogin;

import { App, Form, Input } from 'antd';
import Button from 'components/Button';
import authApis from 'features/auth/services/apis';
import authSelectors from 'features/auth/services/selectors';
import { IBodyAPIVerifyUser } from 'features/auth/services/types/api';
import { debounce, get } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import { useSMLayout } from 'utils/withLayout';

const FormVerify = () => {
  const { notification } = App.useApp();
  const sm = useSMLayout();
  const [form] = Form.useForm();
  const setLoading = useSetLoadingPage();
  const navigate = useNavigate();
  const userInfo = authSelectors.userSignUpInfo();

  const handleVerify = debounce(async (otp: string) => {
    if (!userInfo) return;
    if (!otp || otp.length < 6) return;

    setLoading(true);
    try {
      const body: IBodyAPIVerifyUser = {
        id: userInfo.id,
        confirmCode: otp,
      };
      const res: IResponseDataBody<boolean> = await authApis.verifyUser(body);
      if (res.data.data) {
        notification.success({
          message: 'Verify successfully',
          duration: 3
        });
        navigate('/login');
      }

    } catch (error: any) {
      const msg = get(error, 'response.data.message', '');
      notification.error({
        message: msg || 'wrong OTP!',
        duration: 3
      });
    } finally {
      setLoading(false);
    }
  }, 100);

  const handleSubmitOTP = (data: any) => {
    if (!data || data.length < 6) return;
    handleVerify(data);
  };

  const handleFinish = async (values: { OTP: string }) => {
    if (!values.OTP || values.OTP.length < 6) return;
    handleVerify(values.OTP);
  };

  const getDefaultEmail = () => {
    const username = storage.username.get();
    if (!username) return '';
    if (username === 'undefined') return '';
    return username;
  };

  const handleResend = async (refresh: () => void) => {
    if (!userInfo) return;
    form.resetFields();
    setLoading(true);
    try {
      const res: IResponseDataBody<boolean> = await authApis.resendOTP(userInfo.id);
      if (res.data.data) {
        refresh();
      }
    } catch (error) {
      const msg = get(error, 'response.data.message', '');
      notification.error({
        message: msg || 'Something when wrong!',
        duration: 3,
        props: {
          multiple: false,
        }
      });
    }
    finally {
      setLoading(false);
    }
  };

  const FormInfo = () => {
    return (
      <Form form={form} onFinish={handleFinish} className="form-container" initialValues={{ email: getDefaultEmail() }}>
        <Form.Item name={'OTP'} rules={[{ required: true, message: 'Please enter OTP' }]}>
          <Input.OTP
            onChange={handleSubmitOTP}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                form.submit();
              }
            }}
          />
        </Form.Item>
      </Form >
    );
  };

  if (sm) return (
    <AppStyled>
      <div className="sub-title-note" style={{ marginBottom: '24px' }}>
        <p>Please enter the code that was sent to</p>
        <p>{formatPhone(userInfo?.phone || '')}</p>
        <p>{userInfo?.email || ''}</p>
      </div>
      <FormInfo />
      <Button
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        size='large' onClick={() => form.submit()} block type='primary' style={{ height: '46px', marginTop: '24px' }}>Submit</Button>
      <ButtonResend onClick={handleResend} />
    </AppStyled>
  );
  return (
    <WebStyled>
      <div className="sub-title-note" style={{ marginBottom: '24px' }}>
        <p>Please enter the code that was sent to</p>
        <p>{formatPhone(userInfo?.phone || '')}</p>
        <p>{userInfo?.email || ''}</p>
      </div>
      <FormInfo />
      <Button
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            form.submit();
          }
        }}
        size='large' onClick={() => form.submit()} block type='primary' style={{ height: '46px' }}>Submit</Button>
      <ButtonResend onClick={handleResend} />
    </WebStyled>
  );
};

export default FormVerify;
let clear: any = null;
const COUNTDOWN_RESEND = 5;
const ButtonResend = ({ onClick }: { onClick: (refresh: () => void) => void }) => {
  const [count, setCount] = useState(COUNTDOWN_RESEND);
  useEffect(() => {
    clearTimeout(clear);
    if (count === 0) return;
    clear = setTimeout(() => {
      setCount(previous => previous - 1);
    }, 1000);

  }), [count];
  const refresh = () => setCount(COUNTDOWN_RESEND);

  if (count > 0)
    return (
      <div className='resend-text'>Resend code in {count}s</div>
    );
  return (
    <Button type='text' style={{ marginTop: 4, color: '#0B3558' }} onClick={() => onClick(refresh)}>Resend code</Button>);
};

const Styled = styled.div`
  .sub-title-note {
    color: #0B3558;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-otp {
    width: 100%;
    justify-content: center;
    .ant-input {
      display: flex;
      width: 81px;
      height: 81px;
      padding: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #0B3558;
      text-align: center;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .resend-text {
    color: #808080;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 175% */
  }
`;
const WebStyled = styled(Styled)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  .form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
const AppStyled = styled(Styled)`
  text-align: center;
  .ant-form-item {
    margin-bottom: 20px;
  }
  .ant-otp {
    width: 100%;
    justify-content: center;
    .ant-input {
      display: flex;
      width: 50px;
      height: 50px;
    }
  }
`;

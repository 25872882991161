import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { set } from 'lodash';

const initialState: Record<any, any> = {
  refreshToken: true,
  openImageView: false,
  imagesView: [],
  openAddBlog: false,
};

export const Slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.setOpenAddBlog, (state) => {
        state.openAddBlog = true;
      })
      .addCase(actions.setImageView, (state, { payload }) => {
        state.openImageView = true;
        state.imagesView = payload;
      })
      .addCase(actions.closeImageView, (state) => {
        state.openImageView = false;
        state.imagesView = [];
      })
      .addCase(actions.setLoading, (state, { payload }) => {
        const { path, result } = payload as { path: string, result: boolean };
        set(state, path, result);
      }).addCase(actions.setLoadingPage, (state, { payload }) => {
        set(state, 'loadingApp', payload);
      });
  },
});
const uiServiceReducer = Slice.reducer;
export default uiServiceReducer;

/* eslint-disable react/display-name */
import { Grid } from 'antd';
import { FC } from 'react';
export const useSMLayout = () => {
  const screens = Grid.useBreakpoint();
  return screens.xs;
};
const withLayout = <Props = any,>(UIMobile: FC<Props>, UITablet: FC<Props>) => (props: any) => {
  const screens = Grid.useBreakpoint();

  if (screens.xs) return <UIMobile {...props} />;

  return <UITablet {...props} />;
};

export default withLayout;

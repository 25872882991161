import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { IApiGetBlogParams } from './types/api';

const initialState: IState = {
  data: [],
  mostInteraction: [],
  paging: {
    totalElements: 0,
    totalPage: 0,
  },
  params: {},

  enableSearch: false,
  dataSearch: [],
  paramsSearch: {},
  pagingSearch: {
    totalElements: 0,
    totalPage: 0,
  },
  draftDataReport: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.setDraftDataReport, (state, { payload }) => {
        state.draftDataReport = payload;
      })
      .addCase(actions.updateBlogItem, (state, { payload }) => {
        const idxMI = state.mostInteraction.findIndex(o => o.id === payload.id);
        state.mostInteraction[idxMI] = { ...payload };

        if (state.enableSearch) {
          const idx = state.dataSearch.findIndex(o => o.id === payload.id);
          if (idx !== -1) {
            state.dataSearch[idx] = { ...payload };
          }
          return;
        }

        const idx = state.data.findIndex(o => o.id === payload.id);
        if (idx !== -1) {
          state.data[idx] = { ...payload };
        }
      })
      .addCase(actions.enableSearch, (state, { payload }) => {
        state.enableSearch = payload;
      })
      .addCase(actions.afterAddNew, (state) => {
        state.enableSearch = false;

      })
      .addCase(actions.setBlogsSearchParams, (state, { payload }) => {
        const storeParams = state.paramsSearch;
        const _params: IApiGetBlogParams = {
          ...storeParams,
          ...payload,
        };

        if (_params.keySearch === null || _params.keySearch === undefined || _params.keySearch === '') {
          delete _params.keySearch;
        }
        if (_params.sortBy === null || _params.sortBy === undefined || _params.sortBy === '') {
          delete _params.sortBy;
        }

        state.enableSearch = true;
        state.paramsSearch = _params;
      })
      .addCase(actions.getBlogsSearch.success, (state, { payload }) => {
        state.dataSearch = payload.content;
        state.pagingSearch.totalElements = payload.totalElements;
        state.pagingSearch.totalPage = payload.totalPages;
      })
      .addCase(actions.getBlogsMostInteraction.success, (state, { payload }) => {
        state.mostInteraction = payload.content;
      })
      .addCase(actions.setParams, (state, { payload }) => {
        const storeParams = state.params;
        const _params: IApiGetBlogParams = {
          ...storeParams,
          ...payload,
        };

        if (_params.keySearch === null || _params.keySearch === undefined || _params.keySearch === '') {
          delete _params.keySearch;
        }
        if (_params.sortBy === null || _params.sortBy === undefined || _params.sortBy === '') {
          delete _params.sortBy;
        }

        state.params = _params;
      })
      .addCase(actions.getBlogs.success, (state, { payload }) => {
        state.data = payload.content;
        state.paging.totalElements = payload.totalElements;
        state.paging.totalPage = payload.totalPages;
      })
      ;
  },
});

/** TODO:
 * please replace home => your feature name
 * ex:
 *      my feature is booking 
 *  name sagas like
 *      homeServiceReducer => bookingServiceReducers
 * 
 * // TODO:
 * Move to rootReducer (src/store/root/reducers.ts) and import your feature reducer
 */

const homeServiceReducer = Slice.reducer;
export default homeServiceReducer;

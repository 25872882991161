import { Avatar, Upload, UploadFile, notification } from 'antd';
import ImgCrop from 'antd-img-crop';
import DefaultAvatar from 'assets/Icons/DefaultAvatar';
import Button from 'components/Button';
import mineActions from 'features/mine/services/actions';
import mineApis from 'features/mine/services/apis';
import mineSelectors from 'features/mine/services/selectors';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';

const MAX_SIZE_IMAGE = 800000;
const AvatarUser = () => {
  const dispatch = useAppDispatch();
  const userInfo = mineSelectors.authInfo();
  const setLoading = useSetLoadingPage();
  const [value, onChange] = useState<UploadFile[]>([]);
  const openAlert = debounce(() => {
    notification.error({
      message: 'max size of 800KB',
      duration: 3,
    });
  }, 100);

  const onModalOk = async (_value: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', _value);
    try {
      const res: IResponseDataBody<any> = await mineApis.updateAvatar(formData);
      if (res.data.data) {
        console.log('res', res.data.data);
        dispatch(mineActions.getDetailInfo.fetch());
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  return (
    <UploadAvatarStyled>
      <ImgCrop
        rotationSlider cropShape='round'
        showGrid aspectSlider showReset
        onModalOk={onModalOk}
      >
        <Upload
          name='file'
          accept="image/png, image/jpeg"
          listType="picture-card"
          fileList={value}
          showUploadList={false}
          onChange={({ fileList: newFileList, file }) => {
            if ((file.size || 0) > MAX_SIZE_IMAGE) return openAlert();
            onChange(newFileList);
          }}
        >
          <Button ghost type='text' className='btn-avatar-upload'>
            <Avatar size={80} src={userInfo?.avatar || 'fail'}><DefaultAvatar /></Avatar>
            <div className="avatar-overlay-icon">
              <EditOutlined style={{ color: '#fff', transform:'scale(1.2)' }} />
            </div>
          </Button>
        </Upload>
      </ImgCrop>
    </UploadAvatarStyled>
  );
};

export default AvatarUser;

const UploadAvatarStyled = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  .ant-avatar {
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dde6f4;
    .ant-avatar-string {
      transform: scale(1.5) translateY(3px) !important;
    }
  }
  .title {
    color: #0B3558;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .sub-title {
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-upload-wrapper {
    border-radius: 100%;
    .ant-upload.ant-upload-select {
      border: none;
      border-radius: 100px;
      width: 80px;
      height: 80px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .btn-avatar-upload {
    padding: 4px;
    height: unset;
    border-radius: 100%;
    position: relative;
    .avatar-overlay-icon {
      opacity: 0;
      position: absolute;
      inset: 0;
      border-radius: 100%;
      background: rgba(0,0,0,0.2);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      .avatar-overlay-icon {
        opacity: 1;
      }
    }
  }
`;

import BlogSM, { BlogSMSkeleton } from 'components/Blog/BlogSM';
import React from 'react';
import styled from 'styled-components';
import ReviewPagination from './Pagination';
import EmptyReview from 'components/EmptyReview';
import { IBlogItem } from 'features/home/services/types/blog';
import homeSelectors from 'features/home/services/selectors';
import mineSelectors from 'features/mine/services/selectors';
import authSelectors from 'features/auth/services/selectors';
type Props = {
  loading?: boolean;
  data?: IBlogItem[];
};
const MobileReviewList = ({ }: Props) => {
  const loading = homeSelectors.getBlogLoading();
  const data = homeSelectors.getBlogs();
  const historyActions = mineSelectors.historyActions();
  const authInfo = mineSelectors.authInfo();
  const isAuth = authSelectors.isAuth();

  if (loading) return <LoadingUI />;
  if (!data.length) return <EmptyUI />;

  return (
    <Styled>
      {data.map(o => (
        <BlogSM
          key={o.id}
          data={o}
          historyActions={historyActions}
          authInfo={authInfo}
          isAuth={isAuth}
        />
      ))}
      <ReviewPagination />
    </Styled>
  );
};

export default MobileReviewList;

const LoadingUI = () => (
  <Styled>
    <BlogSMSkeleton />
  </Styled>
);

const EmptyUI = () => (
  <Styled>
    <div className="empty-box">
      <EmptyReview />
    </div>
  </Styled>
);

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 16px;
  padding-left: 16px;
  .empty-box {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    height: 268px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blog-action-item {
    .blog-action-name {
      font-size: 12px;
    }
  }
`;

import React from 'react';
import { SelectProps, Select as SelectUIkit } from 'antd';
import ArrowDown from 'assets/Icons/ArrowDown';
interface Props extends SelectProps {
}
const Select = ({ ...rest }: Props) => {
  return (
    <SelectUIkit
      {...rest}
      suffixIcon={<ArrowDown />}
    />
  );
};

export default Select;

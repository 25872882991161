import React from 'react';

const BusinessPrefix = () => {
  return (
    <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_4009_19923" fill="white">
        <path d="M0 0H38V26H0V0Z" />
      </mask>
      <path d="M37 0V26H39V0H37Z" fill="#E7EDF6" mask="url(#path-1-inside-1_4009_19923)" />
      <g clipPath="url(#clip0_4009_19923)">
        <path d="M12.6687 13.1925H12.1348V19.4592H25.8284V13.1925H25.2945H12.6687ZM21.4572 8.82822C21.3515 7.55413 20.2827 6.54883 18.9816 6.54883C17.6805 6.54883 16.6116 7.55412 16.506 8.82822H12.1348V12.6172H25.8283V8.82822H21.4571H21.4572ZM17.0407 8.82822C17.1442 7.84853 17.975 7.08267 18.9816 7.08267C19.9882 7.08267 20.8189 7.84852 20.9225 8.82822H17.0407H17.0407Z" fill="#808080" />
      </g>
      <defs>
        <clipPath id="clip0_4009_19923">
          <rect width="14" height="14" fill="white" transform="translate(12 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BusinessPrefix;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Styled>

      <div className="exist-account">
        Haven’t got an account yet? <span onClick={() => navigate('/sign-up')}>Sign Up</span>
      </div>
    </Styled>
  );
};

export default Footer;

const Styled = styled.div`
  .agree-text {
    color: #476788;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
    a {
      color: #476788;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      text-decoration-line: underline;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  .exist-account {
    color: #476788;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    span {
      color: #006BFF;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 175% */
      text-decoration-line: underline;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }


`;

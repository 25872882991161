import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IDEMOModelResDataItem } from './types/demoData';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['mine']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['mine']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getDemoData = () => selector('demoData') as IDEMOModelResDataItem[];

const getDemoDataLoading = () => uiSelector.getLoading(PATH_LOADING.getData) as boolean;

const authInfo = () => selector('authInfo') as MyState['authInfo'];

const historyActions = () => selector('historyActions') as MyState['historyActions'];

const searchCustomers = () => selector('searchCustomers') as MyState['searchCustomers'];

const getBlogs = () => selector('data') as MyState['data'];
const getParams = () => selector('params') as MyState['params'];
const getPaging = () => selector('paging') as MyState['paging'];
const getBlogLoading = () => uiSelector.getLoading(PATH_LOADING.getBlogs) as boolean;

const getDraftBlogs = () => selector('draftData') as MyState['draftData'];
const getDraftParams = () => selector('draftParams') as MyState['draftParams'];
const getDraftPaging = () => selector('draftPaging') as MyState['draftPaging'];
const getDraftBlogLoading = () => uiSelector.getLoading(PATH_LOADING.getDraftBlogs) as boolean;

const mineSelectors = {
  getDemoData,
  getDemoDataLoading,
  authInfo,
  historyActions,
  searchCustomers,
  getBlogs,
  getParams,
  getPaging,

  getBlogLoading,
  getDraftBlogs,
  getDraftParams,
  getDraftPaging,
  getDraftBlogLoading,
};
export default mineSelectors;

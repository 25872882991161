import React from 'react';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
import logoIcon from 'assets/logo.png';
import linkedinLogo from 'assets/Icons/linkedin.png';
import twitterLogo from 'assets/Icons/twitter.png';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const sm = useSMLayout();
  const navigate = useNavigate();
  const backToHome = () => navigate('/');
  if (sm) return (
    <AppStyled>
      <img onClick={backToHome} src={logoIcon} className='logo-app' />
      <div className="text-copy-right">© 2024 All rights reserved</div>
      <div className="row-policy">
        <a href="" className="link" style={{ textAlign: 'right' }}>Terms of service</a>
        <a href="" className="link">Privacy Policy</a>
      </div>
      <div className="row-social">
        <a href="" className="social-item"><img src={twitterLogo} /></a>
        <a href="" className="social-item"><img src={linkedinLogo} /></a>
      </div>
    </AppStyled>
  );
  return (
    <WebStyled>
      <div className='ContainerWeb large'>
        <div className="div-logo">
          <img onClick={backToHome} src={logoIcon} className='logo-app' />
        </div>
        <div className='row-policy-center'>
          <div className="text-copy-right">© 2024 All rights reserved</div>
          <div className="row-policy">
            <a href="" className="link" style={{ textAlign: 'right' }}>Terms of service</a>
            <a href="" className="link">Privacy Policy</a>
          </div>
        </div>
        <div className="row-social">
          <a href="" className="social-item"><img src={twitterLogo} /></a>
          <a href="" className="social-item"><img src={linkedinLogo} /></a>
        </div>
      </div>
    </WebStyled>
  );
};

export default Footer;

const Styled = styled.div`
  border-top: 1px solid #E7EDF6;
  background: #FFF;
  .row-policy {
    display: flex;
    align-items: center;
    gap: 32px;
    width: 100%;
    a.link {
      color: #476788;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .row-social {
    display: flex;
    align-items: center;
    gap: 10px;
    .social-item {
      img {
        width: 42px;
        height: 42px;
      }
    }
  }
  .text-copy-right {
    color: #0B3558;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
`;
const AppStyled = styled(Styled)`
  display: flex;
  padding: 16px 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 16px;
  .logo-app {
    height: 28px;
  } 

  .row-policy {
    display: flex;
    align-items: center;
    gap: 32px;
    width: 100%;
    a.link {
      flex: 1;
    }
  }
`;
const WebStyled = styled(Styled)`
  
  padding-bottom: 32px;
  
  background: #FFF;
  display: flex;
  .ContainerWeb {
    align-items: center;
    display: flex;
    gap: 32px;
    padding: 24px 0px;
  }
  gap: 48px;
  .logo-app {
    height: 36px;
  }
  .row-policy-center {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

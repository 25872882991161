import fetch from 'services/request';
import { IApiGetBlogParams, IApiUpdateActionBlog } from './types/api';

const getBlogs = (params: IApiGetBlogParams) => {
  return fetch({
    method: 'get',
    url: '/api/v1/ban-list/blogs',
    params,
  });
};

const getBlogsMostInteraction = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/ban-list/blogs/most-interaction',
  });
};

const updateActionBlog = (body: IApiUpdateActionBlog) => {
  return fetch({
    method: 'post',
    url: '/api/v1/ban-list/users/update-action-blog',
    body,
  });
};

const addBlog = (body: FormData) => {
  return fetch({
    method: 'post',
    url: '/api/v1/ban-list/users/add-review-customer',
    body,
    configs: {
      headers: {
        'accept': '*/*',
        'Content-Type': 'multipart/form-data',
      }
    }
  });
};



const homeApis = {
  addBlog,
  getBlogs,
  getBlogsMostInteraction,
  updateActionBlog,
};

export default homeApis;

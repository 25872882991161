import authServiceSagas from 'features/auth/services/sagas';
import homeServiceSagas from 'features/home/services/sagas';
import { all } from 'redux-saga/effects';
import mineServiceSagas from 'features/mine/services/sagas';
export default function* rootSaga() {
  yield all([
    // define module saga here
    homeServiceSagas(),
    authServiceSagas(),
    mineServiceSagas(),
  ]);
}


import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { IResponseDataBody } from 'services/response';
import storage from 'utils/sessionStorage';
import actions from './actions';
import apis from './apis';
import { IAuthLoginResData, IAuthUserInfo } from 'features/auth/services/types/auth';
import mineActions from './actions';
import { ISagaFunc } from 'services/actionConfigs';
import { HEADER_API } from 'services/request';
import { IBanlistCustomerItem } from './types/customer';
import { notification } from 'antd';
import { IApiGetBlogParams } from 'features/home/services/types/api';
import { setLoading } from 'services/UI/sagas';
import { PATH_LOADING } from './constants';
import { RootState } from 'store';

const getHistoryActions = function* () {
  try {
    const res: IResponseDataBody<any> = yield call(apis.getHistoryActions);
    if (res.data.data) {
      yield put(actions.getHistoryActions.success(res.data.data));
    }
  } catch (error) { }
};
const onAuthUserInfo: ISagaFunc<IAuthLoginResData['user']> = function* ({ payload }) {
  if (!payload) return;
  yield getHistoryActions();
};

const refreshToken = function* () {
  const refreshToken: string = yield storage.refreshToken.get();
  if (!refreshToken) return;
  try {
    const res: IResponseDataBody<IAuthLoginResData> = yield call(apis.getRefreshToken, refreshToken);
    const data = res.data.data;
    if (res.data.data) {
      yield storage.token.set(data.accessToken);
      HEADER_API.token = data.accessToken;
      yield storage.refreshToken.set(data.refreshToken);
      storage.username.set(data.user.email);
      yield put(mineActions.setAuthUserInfo(data.user));
      yield put(mineActions.getSearchCustomers.fetch());
      if (data.user.name)
        notification.success({
          message: `Welcome, ${data.user.name}`,
          duration: 3,
        });
    }
  } catch (error) { }
};

const initMineData = function* () {
  yield delay(200);
  yield all([
    refreshToken(),
  ]);
};

const getSearchCustomers = function* () {
  try {
    const res: IResponseDataBody<IBanlistCustomerItem[]> = yield call(apis.getSearchCustomers);
    if (res.data.data) {
      yield put(actions.getSearchCustomers.success(res.data.data));
    }
  } catch (error) { }
};

const getDetailInfo = function* () {
  try {
    const res: IResponseDataBody<IAuthUserInfo> = yield call(apis.getDetailInfo);
    if (res.data.data) {
      yield put(actions.getDetailInfo.success(res.data.data));
    }
  } catch (error) { }
};

const getBlogs: ISagaFunc<IApiGetBlogParams> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getBlogs, true);
  try {
    const res: IResponseDataBody<any> = yield call(apis.getBlogs, payload);
    if (res.data.data) {
      yield put(actions.getBlogs.success(res.data.data));
    }
  } catch (error) { } finally {
    yield setLoading(PATH_LOADING.getBlogs, false);
  }
};


const setBlogParams: ISagaFunc<IApiGetBlogParams> = function* ({ payload }) {
  const storeParams: IApiGetBlogParams = yield select((state: RootState) => state.mine.params);

  const _params: IApiGetBlogParams = { ...storeParams, ...payload, status: 'PUBLISH' };

  if (_params.keySearch === null || _params.keySearch === undefined || _params.keySearch === '') {
    delete _params.keySearch;
  }
  if (_params.sortBy === null || _params.sortBy === undefined || _params.sortBy === '') {
    delete _params.sortBy;
  }

  yield put(actions.getBlogs.fetch(_params));
};

const getDraftBlogs: ISagaFunc<IApiGetBlogParams> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getDraftBlogs, true);
  try {
    const res: IResponseDataBody<any> = yield call(apis.getBlogs, payload);
    if (res.data.data) {
      yield put(actions.getDraftBlogs.success(res.data.data));
    }
  } catch (error) { } finally {
    yield setLoading(PATH_LOADING.getDraftBlogs, false);
  }
};


const setDraftBlogParams: ISagaFunc<IApiGetBlogParams> = function* ({ payload }) {
  const storeParams: IApiGetBlogParams = yield select((state: RootState) => state.mine.draftParams);

  const _params: IApiGetBlogParams = { ...storeParams, ...payload, status: 'DRAFT' };

  if (_params.keySearch === null || _params.keySearch === undefined || _params.keySearch === '') {
    delete _params.keySearch;
  }
  if (_params.sortBy === null || _params.sortBy === undefined || _params.sortBy === '') {
    delete _params.sortBy;
  }

  yield put(actions.getDraftBlogs.fetch(_params));
};

const initSetting = function* () {
  yield delay(200);
  yield put(actions.setParams({ page: 1, size: 10 }));
  yield put(actions.setDraftParams({ page: 1, size: 10 }));
};

export default function* mineServiceSagas() {
  yield takeLatest(actions.getHistoryActions.fetch, getHistoryActions);
  yield takeLatest(actions.init, initMineData);
  yield takeLatest(actions.setAuthUserInfo, onAuthUserInfo);
  yield takeLatest(actions.getSearchCustomers.fetch, getSearchCustomers);
  yield takeLatest(actions.getDetailInfo.fetch, getDetailInfo);
  yield takeLatest(actions.initSetting, initSetting);
  yield takeLatest(actions.getBlogs.fetch, getBlogs);
  yield takeLatest(actions.setParams, setBlogParams);
  yield takeLatest(actions.getDraftBlogs.fetch, getDraftBlogs);
  yield takeLatest(actions.setDraftParams, setDraftBlogParams);
}

import styled from 'styled-components';
import AddNewReview from './components/AddNewReview';
import Banner from './components/Banner';
import FilterReview from './components/FilterReview';
import Footer from './components/Footer';
import Header from './components/Header';
import MobileReviewList from './components/ReviewList/ReviewList.mobile';
import WriteReviewSection from './components/WriteReviewSection';

const UIMobile = () => {
  return (
    <PageStyled>
      <div className="header">
        <Header />
      </div>
      <Banner />
      <AddNewReview />
      <div style={{ marginBottom: '16px' }} />
      <FilterReview />
      <div style={{ marginBottom: '24px' }} />
      <MobileReviewList />
      <div style={{ marginBottom: '32px' }} />
      <WriteReviewSection />
      <Footer />
    </PageStyled>
  );
};

export default UIMobile;
const PageStyled = styled.div`
  background: #F2F3F8;
  .header {
    position: sticky;
    top: 0;
    z-index: 99;
  }
`;
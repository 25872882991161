import React from 'react';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';
import logoIcon from 'assets/logo.png';
import { useNavigate } from 'react-router-dom';
const Header = () => {
  const sm = useSMLayout();
  const navigate = useNavigate();
  const backToHome = () => navigate('/');
  if (sm) return (
    <AppStyled>
      <img onClick={backToHome} src={logoIcon} className='logo-app' />
    </AppStyled>);

  return (
    <WebStyled>
      <img onClick={backToHome} src={logoIcon} className='logo-app' />
    </WebStyled>
  );
};

export default Header;
const Styled = styled.div`
  background: #F2F3F8;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
const WebStyled = styled(Styled)`
  display: flex;
  height: 55px;
  .logo-app {
    height: 40px;
    flex-shrink: 0;
  }
`;
const AppStyled = styled(Styled)`
  padding-top: 32px;
  .logo-app {
    height: 32px;
    flex-shrink: 0;
  }
`;

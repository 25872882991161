import React from 'react';
import { Avatar as AvatarUIKit, Skeleton } from 'antd';
import DefaultAvatar from 'assets/Icons/DefaultAvatar';
import styled from 'styled-components';
type Props = {
  avatar?: string;
  userName?: string;
};
const Avatar = ({ avatar, userName }: Props) => {
  return (
    <Styled>
      <AvatarUIKit size={40} src={avatar || 'fail'}>
        <DefaultAvatar />
      </AvatarUIKit>
      <span>{userName || 'Unknown'}</span>
    </Styled>
  );
};

export const AvatarSkeleton = () => {
  return (
    <Styled>
      <Skeleton.Avatar active size={'large'} />
      <Skeleton.Input active size='small' />
    </Styled>
  );
};

export default Avatar;
const Styled = styled.div`
  display: flex;
  padding: 4px 12px 4px 4px;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #F2F3F8;
  .ant-avatar {
    border-radius: 100px;
    background: #dde6f4;
  }
  span {
    color: #0B3558;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

import { createAction } from 'services/actionConfigs';
const PREFIX_ACTIONS = 'UI_';
const setLoading = createAction<{ path: string, result: boolean }>(PREFIX_ACTIONS + 'SET_LOADING');
const setLoadingPage = createAction<boolean>(PREFIX_ACTIONS + 'SET_LOADING_PAGE');
const setImageView = createAction<string[]>(PREFIX_ACTIONS + 'SET_IMAGE_VIEW');
const closeImageView = createAction(PREFIX_ACTIONS + 'CLOSE_IMAGE_VIEW');

const setOpenAddBlog =createAction(PREFIX_ACTIONS + 'SET_OPEN_ADD_BLOG');

const uiActions = {
  setLoading,
  setLoadingPage,
  setImageView,
  closeImageView,
  setOpenAddBlog,
};
export default uiActions;

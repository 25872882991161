import { Modal } from 'antd';
import Button from 'components/Button';
import authActions from 'features/auth/services/actions';
import authSelectors from 'features/auth/services/selectors';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';

const ModalGuardLogin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const open = authSelectors.enableGuardLogin();
  const setOpen = (value: boolean) => {
    dispatch(authActions.enableGuardLogin(value));
  };
  const sm = useSMLayout();

  const login = () => {
    setOpen(false);
    navigate('/login');
  };
  const signUp = () => {
    setOpen(false);
    navigate('/sign-up');
  }
    ;

  return (
    <Modal
      open={open}
      forceRender
      footer
      centered
      closable={false}
      onCancel={() => setOpen(false)}
      width={sm ? '327px' : '551px'}
    >
      <Styled sm={sm}>
        <p className="title-modal">Log In</p>
        <p className="message-modal">
          Please log in or register to be able to perform this function and experience more features from the site
        </p>
        <div className="actions">
          <Button onClick={login} block={sm} className='action-item' ghost type='primary' >Log In</Button>
          <Button onClick={signUp} block={sm} className='action-item' type='primary' >Sign Up</Button>
          <Button onClick={() => setOpen(false)} block={sm} className='action-item' grey >Cancel</Button>
        </div>
      </Styled>
    </Modal>
  );
};

export default ModalGuardLogin;

const Styled = styled.div<{ sm?: boolean }>`
  .title-modal {
    color: var(--text1, #0B3558);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }
  .message-modal {
    color: #476788;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 32px;
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 24px;
    .action-item {
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      height: 44px;
    }
  }

  ${({ sm }) => sm ? `

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
    .action-item {
      flex: 1 0 0;
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      height: 44px;
      padding: 4px 8px;
      span {
        font-size: 14px;
      }
    }
  }
    
  `: ''}
`;


import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import loading from './animation_lntwnftg.json';

function Loading() {
  return (
    <>
      <Player
        autoplay={true}
        loop={true}
        controls={true}
        src={loading}
        style={{ height: '100px', width: '100px' }}
      ></Player>
    </>
  );
}

export default Loading;

import { Form } from 'antd';
import React from 'react';
import styled from 'styled-components';
import useNewReviewHook from './useNewReviewHook';
import BoxChooseImage from './BoxChooseImage';
import FormInfo from './FormInfo';
import Header from 'features/home/components/Header';
import Button from 'components/Button';
import MessageErrorSizeImage from './MessageErrorSizeImage';

const NewReviewPage = () => {
  const {
    onClose,
    form, handleFinish, msgErrorImageRef,
    onSaveDraft, onSave,
  } = useNewReviewHook();

  return (
    <Styled>
      <div className="header"><Header /></div>
      <div className="title-page">New Review</div>
      <Form form={form} layout='vertical' className="modal-children" onFinish={handleFinish}>
        <div className="col-children-left">
          <div className="section-title">Images</div>
          <div className="section-subtitle">Maximum 6 images</div>
          <Form.Item name='images' noStyle>
            <BoxChooseImage msgErrorImageRef={msgErrorImageRef} />
          </Form.Item>
        </div>
        <div className="col-children-right">
          <FormInfo />
        </div>
        <div className="footer">
          <Button onClick={onClose} type='default'>Cancel</Button>
          <Button onClick={onSaveDraft} type='primary'>Save as draft</Button>
          <Button onClick={onSave} success>Publish</Button>
        </div>
      </Form>
      <MessageErrorSizeImage ref={msgErrorImageRef} />
    </Styled>
  );
};

export default NewReviewPage;
const Styled = styled.div`
  position: relative;
  .header {
    position: sticky;
    top: 0;
    z-index: 99;
  }
  .footer {
    position: sticky;
    bottom: 0;
    z-index: 99;
    gap: 8px;
    margin: 0 -16px;
    margin-top: 32px;
    border-top: 1px solid #E5E5E5;
    display: flex;
    padding: 16px;
    align-items: center;
    background: #FFF;
    .ant-btn {
      flex: 1;
      height: 48px;
      span {
        font-size: 15px;
      }
    }
  }

  display: flex;
  padding: 0 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  background: #F2F3F8;
  .title-page {
    color: #0B3558;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
  }

  .col-children-right {
    border: 1px solid #D6D6D6;
    padding: 24px 16px;
    background: #FCFCFC;
  }
  .col-children-left {
    border: 1px solid #D6D6D6;
    padding: 24px;
    background: #FCFCFC;
    margin-bottom: 8px;
    .section-title {
      text-align: center;
      color: #0B3558;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .section-subtitle {
      text-align: center;
      color: #476788;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ant-form {
    width: 100%;
    .BoxChooseImageStyled {
      width: 100%;
      .upload-banlist-images .ant-upload.ant-upload-drag {
        align-self: center;
      }
    }
  }
`;

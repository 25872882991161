
import { ISagaFunc } from 'services/actionConfigs';
import { IApiGetDemoDataParam } from './types/api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import apis from './apis';
import actions from './actions';
import { AxiosResponse } from 'axios';
import { IDEMOModelResDataItem } from './types/demoData';
import uiActions from 'services/UI/actions';
import { PATH_LOADING } from './constants';
import { RootState } from 'store';
import storage from 'utils/sessionStorage';

const getDemoData: ISagaFunc<IApiGetDemoDataParam> = function* () {
  yield put(uiActions.setLoading({ path: PATH_LOADING.getData, result: true }));
  try {
    const resData: AxiosResponse<{ data: IDEMOModelResDataItem[] }> = yield call(apis.getDemoData);
    if (resData?.data?.data) {
      yield put(actions.getDemoData.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getDemoData.fail({}));
  } finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.getData, result: false }));
  }
};

export const guardLogin = function* () {
  const isAuth: boolean = yield select((state: RootState) => state.auth.isAuth);
  if (!isAuth) {
    yield put(actions.enableGuardLogin(true));
    return false;
  }
  return true;
};

const logout = function* () {
  yield storage.token.set('');
  yield storage.refreshToken.set('');
};

export default function* authServiceSagas() {
  yield takeLatest(actions.getDemoData.fetch, getDemoData);
  yield takeLatest(actions.logout, logout);
}

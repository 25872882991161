import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from './NotFound';
import HomePage from './home/index';
import LoginPage from './auth/pages/LoginPage';
import SignUpPage from './auth/pages/SignUpPage';
import SideEffectInit from './SideEffectInit';
import NewReviewPage from 'widgets/NewReview/NewReviewPage';
import VerifyPage from './auth/pages/VerifyPage';
import AccountSettingPage from './mine/pages/AccountSettingPage';
import GuardLogin from './GuardLogin';

const RootScreens = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path='/' element={<SideEffectInit />}>
          <Route path='' element={<HomePage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='sign-up' element={<SignUpPage />} />
          <Route path='' element={<GuardLogin />}>
            <Route path='new-review' element={<NewReviewPage />} />
            <Route path='account-setting' element={<AccountSettingPage />} />
          </Route>
          <Route path='verify-otp' element={<VerifyPage />} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </React.Fragment>
  );
};

export default RootScreens;

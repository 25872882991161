import authServiceReducer from 'features/auth/services/reducers';
import homeServiceReducer from 'features/home/services/reducers';
import uiServiceReducer from 'services/UI/reducer';
import mineServiceReducer from 'features/mine/services/reducers';

const rootReducer = {
  ui: uiServiceReducer,
  home: homeServiceReducer,
  auth: authServiceReducer,
  mine: mineServiceReducer,
};

export default rootReducer;

import { Pagination } from 'antd';
import homeActions from 'features/home/services/actions';
import homeSelectors from 'features/home/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { useSMLayout } from 'utils/withLayout';

const ReviewPagination = () => {
  const dispatch = useAppDispatch();
  const sm = useSMLayout();
  const paging = homeSelectors.getPaging();
  const params = homeSelectors.getParams();
  const enableSearch = homeSelectors.getEnableSearch();
  const handleChangePage = (page: number) => {
    if (enableSearch) return dispatch(homeActions.setBlogsSearchParams({ page }));
    dispatch(homeActions.setParams({ page }));
  };

  return (
    <Styled>
      <Pagination
        defaultCurrent={1} total={paging.totalElements}
        showSizeChanger={false}
        current={params.page || 1}
        pageSize={params.size || 10}
        onChange={handleChangePage}
        showLessItems={sm}
        itemRender={(page, type) => {
          switch (type) {
            case 'prev':
              return <div className='item-paging'><IconPrev /></div>;
            case 'next':
              return <div className='item-paging'><IconNext /></div>;
            case 'jump-prev':
            case 'jump-next':
              return <div className='item-paging'><span>...</span></div>;
            default:
              return <div className='item-paging'><span>{page}</span></div>;
          }
        }}
      />
    </Styled>
  );
};

export default ReviewPagination;

const Styled = styled.div`
  .ant-pagination {
    li {
      width: 40px;
      height: 40px;
     
      gap: 10px;
      border: 1px solid #E7EDF6;
      background: #FFF;
      position: relative;
      &.ant-pagination-next, &.ant-pagination-prev{
        background: transparent;
        border-color: transparent;
      }
      .item-paging {
        position: absolute;
        inset: 0;
        padding: 5px 7px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        span {
          color: #476788;
          text-align: center;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 125% */
        }
      }

    }
    .ant-pagination-item {
      &.ant-pagination-item-active {
        background: #006BFF;
        span {
          color: #fff;
        }
        a {
          color: #FFF;
        }
      }
    }
  }
`;

const IconPrev = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.4697 2.61963L12.5304 3.68029L7.22709 8.98359L12.5304 14.2869L11.4697 15.3476L5.63614 9.51397C5.63611 9.51394 5.6361 9.51392 6.16643 8.98359L5.63614 9.51397L5.10577 8.98359L11.4697 2.61963Z" fill="#476788" />
  </svg>
);
const IconNext = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.53027 15.3804L5.46961 14.3197L10.7729 9.01641L5.46961 3.71311L6.53027 2.65245L12.3639 8.48603C12.3639 8.48606 12.3639 8.48608 11.8336 9.01641L12.3639 8.48603L12.8942 9.01641L6.53027 15.3804Z" fill="#476788" />
  </svg>
);

import React from 'react';
import styled from 'styled-components';
import FormLogin from './components/FormLogin';
import Footer from './components/Footer';
import Header from 'features/auth/components/Header';

const UIMobileLoginPage = () => {
  return (
    <Styled>
      <Header />
      <div style={{ marginBottom: '32px' }} />
      <FormLogin />
      <div style={{ marginBottom: '16px' }} />
      <Footer />
      <div style={{ marginBottom: '16px' }} />
    </Styled>
  );
};

export default UIMobileLoginPage;
const Styled = styled.div`
  background: #F2F3F8;
  padding-right: 16px;
  padding-left: 16px;
`;
/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR FEATURE NAME HERE
 * ex:
 *  NAME_REDUCER = 'NAME_REDUCER'
 *   => 
 *  NAME_REDUCER = 'booking'
 */
export const NAME_REDUCER = 'mine';

/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR PREFIX ACTION NAME HERE
 * ex:
 *  PREFIX_ACTIONS = 'PREFIX_ACTIONS';
 *   => 
 *  PREFIX_ACTIONS = 'booking_feature_';
 */
export const PREFIX_ACTIONS = 'mine_feature_';


export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
  getBlogs: `loading.${NAME_REDUCER}.getBlogs`,
  getDraftBlogs: `loading.${NAME_REDUCER}.getDraftBlogs`,
};

import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IDEMOModelResDataItem } from './types/demoData';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { IUserSignUpInfo } from './types/auth';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['auth']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['auth']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getDemoData = () => selector('demoData') as IDEMOModelResDataItem[];

const getDemoDataLoading = () => uiSelector.getLoading(PATH_LOADING.getData) as boolean;

const enableGuardLogin = () => selector('enableGuardLogin') as boolean;
const isAuth = () => selector('isAuth') as boolean;

const userSignUpInfo = () => selector('userSignUpInfo') as IUserSignUpInfo | null;

const authSelectors = {
  getDemoData,
  getDemoDataLoading,
  enableGuardLogin,
  isAuth,
  userSignUpInfo,
};
export default authSelectors;
